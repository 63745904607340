import { useEffect, useState } from "react"
import CarElement from "../Components/CarElement/CarElement"
import axios from "axios";

const Cars = () => {
    const [page, setPage] = useState(1);
    const [cars, setCars] = useState([]);
    const [total, setTotal] = useState(0);
    const [filter, setFilter] = useState({});

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const queryObject = {};
        for (const [key, value] of queryParams.entries()) {
            queryObject[key] = value;
        }

        const {pickup, dropoff, mark, model, count, start, end} = queryObject;
        setFilter({...queryObject});

        let locationFee = 0;
        if (pickup === "Kutaisi Office") locationFee += 0;
        if (pickup === "Kutaisi Center") locationFee += 5;
        if (pickup === "Kutaisi Airport") locationFee += 15;
        if (pickup === "Tbilisi Center") locationFee += 45;
        if (pickup === "Tbilisi Airport") locationFee += 55;
        if (pickup === "Batumi Center") locationFee += 45;
        if (pickup === "Batumi Airport") locationFee += 55;
        if (pickup === "Other") locationFee += 70;
        if (dropoff === "Kutaisi Office") locationFee += 0;
        if (dropoff === "Kutaisi Center") locationFee += 5;
        if (dropoff === "Kutaisi Airport") locationFee += 15;
        if (dropoff === "Tbilisi Center") locationFee += 45;
        if (dropoff === "Tbilisi Airport") locationFee += 55;
        if (dropoff === "Batumi Center") locationFee += 45;
        if (dropoff === "Batumi Airport") locationFee += 55;
        if (dropoff === "Other") locationFee += 70;
        
        axios(`/api/product?limit=20&page=${page}&type=Car${typeof mark !== "undefined" ? `&mark=${mark}` : ""}${typeof model !== "undefined" ? `&model=${model}` : ""}`)
            .then((res) => {
                const hours = (parseInt(end) - parseInt(start)) / 3600000;
                const data = res.data.data.map((c) => {
                    return {...c, startingPrice: count ? parseInt(((c.startingPrice / 24) * hours) + locationFee) : c.startingPrice}
                });
                if (page === 1) setCars([...data]);
                if (page > 1) setCars((prev) => ([...prev, ...data]));
                setTotal(res.data.total)
            })
            .catch((err) => console.log(err))
    }, [page]);
    return (
        <div className="container">
            <div className="row">
                <h3 style={{ marginTop: "50px" }} className="section-title">Cars</h3>
                {
                    cars.map((c) => {
                        return (
                            <CarElement key={c.id} data={c} filter={filter} />
                        )
                    })
                }
                {total - 1 > cars.length && <div className="load-more-button-container">
                    <button className="load-more-button" onClick={() => setPage((prev) => prev + 1)}>Load More</button>
                </div>}
            </div>
        </div>
    )
}

export default Cars