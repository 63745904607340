const TermsAndConditions = () => {
    return (
        <div className="container terms-and-conditions">
            <h3 className="section-title">TERMS AND RULES</h3>
            <h5>THE NECESSARY CONDITION FOR DRIVING A VEHICLE.</h5>
            <ul>
                <li>A rented vehicle can be used by of any country citizen since the age of 21, which has valid driving license in the territory of Georgia (Is not required international driving license).</li>
                <li>If a vehicle is returned with less gasoline than mentioned in the Rental Agreement, the Renter will be charged for refueling service of 1.00 per liter.</li>
                <li>The Renter is fully liable for the damage of the rented car caused by usage of other type of gasoline.</li>
                <li>Car rent in Georgia will charge a penalty of 300.00 USD, in case of lost car documents or keys.</li>
            </ul>
            <h5>THE RENTER IS OBLIGED</h5>
            <ul>
                <li>Carry a vehicle to the hired person at the ordered place on time.</li>
                <li>The vehicle completely is in good condition.</li>
                <li>The vehicle is insured.</li>
                <li>In the case of technical disruption of the vehicle.</li>
                <li>The owner is obligated to provide assistance to the hired contractor.</li>
            </ul>
            <h5>THE HIRER IS OBLIGATED</h5>
            <ul>
                <li>Drive a vehicle in a sober state</li>
                <li>Control water and oil indicators during the driving.</li>
                <li>Obey the rules and norms envisaged by the Georgian legislation.</li>
                <li>Return the car as the original condition.</li>
                <li>In case of unexpected (auto transport, robbery, theft, natural disaster, etc.), address immediately the law enforcement structure (number 112).</li>
                <li>During the driving, expect the insurance case, the cost of damage caused by the driver's guilty shall be paid to the renter.</li>
                <li>The "Lessee" is obliged to notify the company about the delay in temporary use of the car 24 hours before, otherwise will be charged at 10.oo USD per delayed hour.</li>
                <li>The "Lessee" did not fulfill the obligations undertaken by the contract and the vehicle was not returned without the prior notification to the company within 3 hours late of expire the origin time set by the contract , company is obliged to provide information to the relevant authorities for the search for the vehicle. "Lessee" agrees to take full responsibility and reimburse the full loss if it is under the influence of alcohol or drugs or is not a protocol confirmed by the patrol police. Insurance does not compensate for the hired car tire, suspension, engine, gearbox and bridges, etc. If it is caused by negligence of the driver and not associated with the auto-road accident. In case of full loss, the employer will pay 20% of the insurance market value of the vehicle market value.</li>
                <li>The relationship between the owner and the hirer shall be regulated by the Treaty and the Georgian legislation</li>
            </ul>
            <h5>RESERVATION</h5>
            <ul>
                <li>You can do online reservation by www.carrentingeorgia.ge according to the terms specified on the site</li>
                <li>Reservation is available during the 24 hours a day - info.carrentingeorgia@gmail.com</li>
                <li>Reservation is available via phone 24/7 - (+995) 555 53 49 49 / 593 71 56 25</li>
                <li>Reservation by phone or mail need to be verified by the renter (by Literally or ) writing.</li>
                <li>Cancellation can be canceled 24 hours earlier before the order.</li>
            </ul>
            <h5>TERMS OF PAYMENT</h5>
            <ul>
                <li>The lender does not require deposit.</li>
                <li>100% advance payment at the time of starting a car order.</li>
                <li>At the time of reservation, it’s possible 40% advance payment (with bank transfers) requested by the lessee.</li>
            </ul>
            <h5>THAT DOES NOT INCLUDE RENTAL FEE</h5>
            <ul>
                <li>Washing the car upon return.</li>
                <li>Payment for parking.</li>
                <li>The cost of fuel for the period of hire a car.</li>
                <li>Damage to the vehicle, which are not covered by insurance or not declared to the insurance company and the police.</li>
                <li>The loss documents, keys and the number plate on the car.</li>
            </ul>
            <h5>INSURANCE CONDITIONS</h5>
            <ul>
                <li>The Driver Must Be At Least 21 Years Old And Hold Relevant Driving License.</li>
                <li><strong>With Auto Insurance You Are Insured Against The Following Risks:</strong></li>
                <li>Road Accident; Larceny, Theft or Robbery; Fire, Explosion; Vandalism; Falling Objects; Natural Disasters (Including, Earthquake, Hail); Accessories; Third Part Liability Insurance (MTPL).</li>
                <li><strong>Instructions For Action In Case Of An Insurance Accident:</strong></li>
                <li>Do Not Move The Vehicle From The Incident Location; Upon Occurrence Of Incident Call The Patrol Police To Register The Incident - 112! Call To The Hotline Of Our Call-Center: +995 32 2 505 111 Served By An Insurance Company GPI Vienna Insurance Group.</li>
            </ul>
        </div>
    )
}

export default TermsAndConditions