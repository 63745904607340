import { Button, Modal, Table } from "react-bootstrap"
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useRef, useState } from "react";
import logo from './logo.png'
import sign from './sign.png'
import sign2 from './sign2.png'

const OrderDetailsModal = ({ orderId, data, days, location, show, price, setShow, image, submit }) => {
    const pdfRef = useRef();
    const [showButtons, setShowButtons] = useState(true);
    const handleGeneratePdf = () => {
        const input = pdfRef.current;

        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');

                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgWidth = 190; // Adjust these dimensions
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
                pdf.save('invoice.pdf');
                setShow(false);
            })
            .catch((error) => {
                console.error('html2canvas error:', error);
            });
    };

    return (
        <Modal show={show} onHide={() => setShow(false)} centered size="xl">
            <Modal.Header><h4>Check order details</h4></Modal.Header>
            <Modal.Body>
                <div className="container" ref={pdfRef}>
                    <div className="row">
                        <div className="order-details-image-container">
                            <img src={logo} alt="logo" className="logo-in-details" />
                        </div>
                        <div className="col-md-7">
                            <h5 style={{ color: "red" }}>PICKUP DETAILS</h5>
                            <p><strong>Mark And Model: </strong> {data.mark} {data.model}</p>
                            <p><strong>From: </strong> {data.pickupDate.replace("T", " ")}</p>
                            <p><strong>To: </strong> {data.dropOffDate.replace("T", " ")}</p>
                            <p><strong>Pickup:</strong> {data.pickupLocation}</p>
                            <p><strong>Dropoff:</strong> {data.dropOffLocation}</p>
                        </div>
                        <div className="col-md-5">
                            <h4 style={{ color: "red" }}>CAR RENT IN GEORGIA</h4>
                            <p style={{ marginBottom: "2px" }}>Cheap Operator</p>
                            <p style={{ marginBottom: "2px" }}>Georgia. Kutaisi. G. Tabidze str. 23</p>
                            <p style={{ marginBottom: "2px" }}><strong>Mob.</strong> +995 555 534 949</p>
                            <p style={{ marginBottom: "2px" }}><strong>Viber/WhatsApp.</strong> +995 555 534 949 / +995 577108017</p>
                            <a href="www.crg.ge" target="_blank">www.crg.ge</a>
                            <br></br>
                            <a href="www.carrentingeorgia.ge" target="_blank">www.carrentingeorgia.ge</a>
                        </div>
                        <div className="col-md-12">
                            <Table responsive style={{ marginTop: "35px" }}>
                                <thead>
                                    <tr>
                                        <th>Service name</th>
                                        <th>Days</th>
                                        <th>Net price</th>
                                        <th>Tax</th>
                                        <th>Total price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{data.mark} {data.model} Rental Cost</td>
                                        <td>{days}</td>
                                        <td>{price} €</td>
                                        <td>0 €</td>
                                        <td>{price} €</td>
                                    </tr>
                                    <tr>
                                        <td>Pickup/Drop Off Fee</td>
                                        <td>{days}</td>
                                        <td>{location} €</td>
                                        <td>0 €</td>
                                        <td>{location} €</td>
                                    </tr>
                                    {data.gps && <tr>
                                        <td>GPS System</td>
                                        <td>{days}</td>
                                        <td>{5 * days} €</td>
                                        <td>0 €</td>
                                        <td>{5 * days} €</td>
                                    </tr>}
                                    {data.babySet && <tr>
                                        <td>Baby Set</td>
                                        <td>{days}</td>
                                        <td>{5 * days} €</td>
                                        <td>0 €</td>
                                        <td>{5 * days} €</td>
                                    </tr>}
                                    {data.topBag && <tr>
                                        <td>Top Bag</td>
                                        <td>{days}</td>
                                        <td>{10 * days} €</td>
                                        <td>0 €</td>
                                        <td>{10 * days} €</td>
                                    </tr>}
                                    {data.mp3Connector && <tr>
                                        <td>MP3 Connector</td>
                                        <td>{days}</td>
                                        <td>{0 * days} €</td>
                                        <td>0 €</td>
                                        <td>{0 * days} €</td>
                                    </tr>}
                                    {data.skyCarRack && <tr>
                                        <td>Sku Car Rack</td>
                                        <td>{days}</td>
                                        <td>{8 * days} €</td>
                                        <td>0 €</td>
                                        <td>{8 * days} €</td>
                                    </tr>}
                                    {data.wifiPack && <tr>
                                        <td>WIFI Pack</td>
                                        <td>{days}</td>
                                        <td>{15 * days} €</td>
                                        <td>0 €</td>
                                        <td>{15 * days} €</td>
                                    </tr>}
                                    {data.msrSuperFly && <tr>
                                        <td>MSR Super Fly</td>
                                        <td>{days}</td>
                                        <td>{4 * days} €</td>
                                        <td>0 €</td>
                                        <td>{4 * days} €</td>
                                    </tr>}
                                    {data.fishingRod && <tr>
                                        <td>Fishing Rod</td>
                                        <td>{days}</td>
                                        <td>{3 * days} €</td>
                                        <td>0 €</td>
                                        <td>{3 * days} €</td>
                                    </tr>}
                                    {data.driverInCity && <tr>
                                        <td>Driver In City</td>
                                        <td>{days}</td>
                                        <td>{25 * days} €</td>
                                        <td>0 €</td>
                                        <td>{25 * days} €</td>
                                    </tr>}
                                    {data.driverOutOfCity && <tr>
                                        <td>Driver Out Of City</td>
                                        <td>{days}</td>
                                        <td>{35 * days} €</td>
                                        <td>0 €</td>
                                        <td>{35 * days} €</td>
                                    </tr>}
                                    {data.tents2x > 0 && <tr>
                                        <td>Tents 2x {`(${data.tents2x}x)`}</td>
                                        <td>{days}</td>
                                        <td>{data.tents2x * 5 * days} €</td>
                                        <td>0 €</td>
                                        <td>{data.tents2x * 5 * days} €</td>
                                    </tr>}
                                    {data.tents3x > 0 && <tr>
                                        <td>Tents 2x {`(${data.tents3x}x)`}</td>
                                        <td>{days}</td>
                                        <td>{data.tents3x * 7 * days} €</td>
                                        <td>0 €</td>
                                        <td>{data.tents3x * 7 * days} €</td>
                                    </tr>}
                                    {data.sleepingBag > 0 && <tr>
                                        <td>SLEEPING BAG 3X {`(${data.sleepingBag}x)`}</td>
                                        <td>{days}</td>
                                        <td>{data.sleepingBag * 3 * days} €</td>
                                        <td>0 €</td>
                                        <td>{data.sleepingBag * 3 * days} €</td>
                                    </tr>}
                                    {data.sleepingBag3x > 0 && <tr>
                                        <td>SLEEPING BAG 3X {`(${data.sleepingBag3x}x)`}</td>
                                        <td>{days}</td>
                                        <td>{data.sleepingBag3x * 4 * days} €</td>
                                        <td>0 €</td>
                                        <td>{data.sleepingBag3x * 4 * days} €</td>
                                    </tr>}
                                    {data.paralon > 0 && <tr>
                                        <td>Paralon {`(${data.paralon}x)`}</td>
                                        <td>{days}</td>
                                        <td>{data.paralon * 2 * days} €</td>
                                        <td>0 €</td>
                                        <td>{data.paralon * 2 * days} €</td>
                                    </tr>}
                                    <tr>
                                        <td style={{ background: "gray", color: "white" }}>ToTal</td>
                                        <td style={{ background: "gray", color: "white" }}>{days}</td>
                                        <td style={{ background: "gray", color: "white" }}>{data.priceSum} €</td>
                                        <td style={{ background: "gray", color: "white" }}>0 €</td>
                                        <td style={{ background: "gray", color: "white" }}>{data.priceSum} €</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-md-6">
                            <h4 style={{ marginTop: "30px" }}>Transaction Details</h4>
                            <div className="personal-details-containersi">
                                {orderId !== 0 && <div style={{ display: "flex", alignItems: "center" }}><span style={{ width: "150px" }}>Order ID:</span><span> {orderId}</span></div>}
                                <div style={{ display: "flex", alignItems: "center" }}><span style={{ width: "150px" }}>Beneficiary’s Bank:</span><span> JSC TBC</span></div>
                                <div style={{ display: "flex", alignItems: "center" }}><span style={{ width: "150px" }}>Bank Bank Code:</span><span> TBCBGE22</span></div>
                                <div style={{ display: "flex", alignItems: "center" }}><span style={{ width: "150px" }}>Beneficiary’s IBAN:</span><span> GE39TB7563736120100003</span></div>
                                <div style={{ display: "flex", alignItems: "center" }}><span style={{ width: "150px" }}>Name of Beneficiary:</span><span> LTD CAR RENT IN GEORGIA</span></div>
                                <div style={{ display: "flex", alignItems: "center" }}><span style={{ width: "150px" }}>Intermediary Bank:</span><span> J.P. Morgan SE</span></div>
                                <div style={{ display: "flex", alignItems: "center" }}><span style={{ width: "150px" }}>Intermediary Bank Address:</span><span> Frankfurt am Main, Germany</span></div>
                                <div style={{ display: "flex", alignItems: "center" }}><span style={{ width: "150px" }}>SWIFT:</span><span> CHASDEFX</span></div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h4 style={{ marginTop: "30px" }}>Personal Details</h4>
                            <div className="personal-details-containersi">
                                <div style={{ display: "flex", alignItems: "center" }}><span style={{ width: "150px" }}>First Name:</span><span> {data.name}</span></div>
                                <div style={{ display: "flex", alignItems: "center" }}><span style={{ width: "150px" }}>Last Name:</span><span> {data.surname}</span></div>
                                <div style={{ display: "flex", alignItems: "center" }}><span style={{ width: "150px" }}>Email:</span><span> {data.email}</span></div>
                                <div style={{ display: "flex", alignItems: "center" }}><span style={{ width: "150px" }}>Age:</span><span> {data.age}</span></div>
                                <div style={{ display: "flex", alignItems: "center" }}><span style={{ width: "150px" }}>Phone Number:</span><span> {data.phone}</span></div>
                            </div>
                            <img src={sign2} alt="sign" style={{width: "170px", marginLeft: "170px", marginTop: "50px"}} />
                            <br />
                            <img src={sign} alt="sign" style={{width: "170px", marginLeft: "170px", marginTop: "0px"}} />
                        </div>
                        {showButtons &&
                            <>
                                <div className="col-md-3">
                                    <Button variant="dark" style={{ width: "100%", marginTop: "30px" }} onClick={() => setShow(false)}>Return to edit</Button>
                                </div>
                                <div className="col-md-6"></div>
                                <div className="col-md-3">
                                    <button className="orange-button" style={{ width: "100%", marginTop: "30px" }} onClick={() => {
                                        submit();
                                        setShowButtons(false);
                                        setShow(false);
                                    }}>Submit order</button>
                                </div>
                            </>
                        }
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default OrderDetailsModal