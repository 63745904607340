import { useEffect, useState } from "react"
import BannerSlider from "../Common/BannerSlider"
import AdditionalServices from "../Components/AdditionalServices/AdditionalServices"
import CarElement from "../Components/CarElement/CarElement"
import CarSearchFilter from "../Components/CarSearchFilter/CarSearchFilter"
import Services from "../Components/Services/Services"
import axios from "axios"

const Main = () => {
    const [cars, setCars] = useState([]);
    const [bike, setBike] = useState([]);
    const [moped, setMoped] = useState([]);

    useEffect(() => {
        axios(`/api/cars?limit=4&page=1&type=Car`)
            .then((res) => {
                const data = res.data.data;
                setCars([...data]);
            })
            .catch((err) => {
                console.log(err);
            })
        axios(`/api/cars?limit=4&page=1&type=Bike`)
            .then((res) => {
                const data = res.data.data;
                setBike([...data]);
            })
            .catch((err) => {
                console.log(err);
            })
        axios(`/api/cars?limit=4&page=1&type=Moped`)
            .then((res) => {
                const data = res.data.data;
                setMoped([...data]);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])

    const images = [{ img: "https://crg.ge/static/images/slide-116187944.webp", title: "Rent And Go" }, { img: "https://crg.ge/static/images/slide-875516248.webp", title: "Bicycle rental" }, { img: "https://crg.ge/static/images/slide-412878954862.webp", title: "Camp rental" }]
    return (
        <>
            <BannerSlider images={images} autoPlay={true} autoPlayInterval={5000} />
            <div className="services-on-slicer">
                <div className="container full-width">
                    <Services />
                </div>
            </div>
            <CarSearchFilter />
            <div className="container">
                <h3 className="section-title">Cars</h3>
                <div className="row">{cars.map((car) => {
                    return <CarElement key={car.id} data={car} />
                })}</div>
                <h3 className="section-title">Mopeds</h3>
                <div className="row">{moped.map((m) => {
                    return <CarElement key={m.id} data={m} />
                })}</div>
                <h3 className="section-title">Bicycles</h3>
                <div className="row">{bike.map((b) => {
                    return <CarElement key={b.id} data={b} />
                })}</div>
            </div>
            <div className="information-banner">
                <div className="banner-text-box">
                    <p className="banner-text">
                        THE VEHICLES ARE OFFERED IN THE PROPER TECHNICAL CONDITION, THE DEPOSIT IS NOT REQUIRED.
                        CARS WILL BE EQUIPPED WITH THE GPS NAVIGATION SYSTEM, THE CHILDREN'S CHAIR AND THE ADDITIONAL LUGGAGE ACCORDING TO
                        YOUR REQUEST. THE PRICE INCLUDES AN IDEAL INSURANCE PACKAGE. DURING YOUR TRIP YOU CAN ENJOY OUR 24 HOUR SERVICE. WE WISH YOU
                        APEACEFUL AND COMFORTABLE TRIP WITH,,CARRENTINGEORGIA”
                    </p>
                </div>
            </div>
            <div className="container">
                <AdditionalServices />
            </div>
        </>
    )
}

export default Main