import axios from "axios";
import { useEffect, useState } from "react";
import DataTable from "../../../Common/DataTable/DataTable";
import Notification from "../../../Hooks/Notification";
import AddChargesModal from "../Modals/AddChargesModal";
import { Button, Modal } from "react-bootstrap";

const AdminExpenses = ({role}) => {
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [expenses, setExpenses] = useState([]);
    const [notification, setNotification] = useState({ show: false, title: "", message: "" });
    const [totalExpense, setTotalExpense] = useState("");
    const d = new Date();
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    const [date, setDate] = useState({ from: "", to: `${year}-${month}-${day}` });
    const [formData, setFormData] = useState({ mark: "", model: "", year: "", carId: "", date: "", type: "", price: "", description: "", expenseType: "" })
    const [showAddExpenseModal, setShowAddExpenseModal] = useState(false);
    const [action, setAction] = useState("add");
    const [cars, setCars] = useState([]);
    const [active, setActive] = useState({});
    const [showDeleteExpenseModal, setShowDeleteExpenseModal] = useState(false);

    const headers = ["Mark", "Model", "Year", "Car ID", "Date", "Type", "Price", ""];
    const showParams = [
        { type: "string", name: "mark" }, { type: "string", name: "model" }, { type: "string", name: "year" }, { type: "string", name: "carId" },
        { type: "date", name: "date" }, { type: "string", name: "expenseType" }, { type: "string", name: "price" }
    ];

    const handlers = {
        search(keyword) {
            setKeyword(keyword);
        },
        addNew(e) {
            e.preventDefault();
            setFormData((prev) => ({ ...prev, date: prev.date.replaceAll("-", "/") }));
            axios.post(`/api/expenses`, { ...formData, date: formData.date.replaceAll("-", "/") })
                .then(() => {
                    setExpenses((prev) => ([{ ...formData }, ...prev]));
                    setShowAddExpenseModal(false);
                    setNotification({ show: true, title: "Success", message: "Expence added successfully" });
                })
                .catch((err) => {
                    const data = err.response.data;
                    setNotification({ show: true, title: data.description, message: data.message })
                })
        },
        edit(e) {
            e.preventDefault();
            setFormData((prev) => ({ ...prev, date: prev.date.replaceAll("-", "/") }));
            axios.put(`/api/expenses/${formData.id}`, { ...formData, date: formData.date.replaceAll("-", "/") })
                .then(() => {
                    const data = expenses.map((e) => {
                        if (e.id === formData.id) {
                            return { ...formData }
                        } else {
                            return { ...e }
                        }
                    })
                    setExpenses([...data]);
                    setShowAddExpenseModal(false);
                    setNotification({ show: true, title: "Success", message: "Expence edited successfully" });
                })
                .catch((err) => {
                    const data = err.response.data;
                    setNotification({ show: true, title: data.description, message: data.message })
                })
        },
        delete() {
            axios.delete(`/api/expenses/${formData.id}`)
                .then(() => {
                    const data = expenses.filter((e) => e.id !== formData.id);
                    setExpenses([...data]);
                    setShowDeleteExpenseModal(false);
                    setNotification({ show: true, title: "Success", message: "Expence removed successfully" });
                })
                .catch((err) => {
                    const data = err.response.data;
                    setShowDeleteExpenseModal(false);
                    setNotification({ show: true, title: data.description, message: data.message })
                })
        }
    }

    const rowActions = role === "admin" ? ([
        {
            name: "Edit",
            id: 1001,
            action: (act) => {
                const date = new Date(act.date);
                setFormData({ ...act, date: `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}` });
                setShowAddExpenseModal(true);
                setAction("edit");
            }
        },
        {
            name: "Delete",
            id: 1003,
            action: (act) => {
                setFormData({ ...act })
                setShowDeleteExpenseModal(true)
            }
        }
    ]) : [];

    const actions = [
        {
            name: "Add new",
            action: () => {
                setFormData({ mark: "", model: "", year: "", carId: "", date: "", type: "", price: "", description: "", expenseType: "" });
                setShowAddExpenseModal(true);
                setAction("add");
            }
        }
    ];

    useEffect(() => {
        axios.get(`/api/cars?limit=150&page=1`)
            .then((res) => {
                const data = res.data.data;
                setCars([...data]);
            })
            .catch((err) => {

            })
        axios(`/api/expenses?keyword=${keyword}&limit=${limit}&page=${page}&startDate=${date.from}&endDate=${date.to}`)
            .then((res) => {
                const data = res.data.data;
                setExpenses([...data]);
                setTotal(res.data.total);
                setTotalExpense(res.data.price);
            })
            .catch((err) => {
                const data = err.response.data;
                setNotification({ show: true, title: data.description, message: data.message })
            })
    }, [keyword, page, limit, date]);

    return (
        <>
            <div className="container">
                <div className="row">
                    <div>
                        <DataTable
                            withoutSearchButton={false}
                            headers={headers}
                            searchHandler={handlers.search}
                            title={"Expenses"}
                            limit={limit}
                            actionButtons={actions}
                            setLimit={setLimit}
                            limitControl={true}
                            data={expenses}
                            showParams={showParams}
                            pageChanged={page}
                            setPageChanged={setPage}
                            total={total}
                            rowActions={role === "admin" ? true : false}
                            rowActionsList={rowActions}
                            dateRange={true}
                            setActiveRow={setActive}
                            setDate={setDate}
                            date={date}
                            withSelectFilter={false}
                        />
                        <div>
                            <p>Total Amount: <strong>{totalExpense} GEL</strong></p>
                        </div>
                    </div>
                    <Notification title={notification.title} message={notification.message} show={notification.show} setShow={setNotification} />
                    <AddChargesModal show={showAddExpenseModal} cars={cars} hide={setShowAddExpenseModal} formData={formData} setFormData={setFormData} action={action} onSubmit={action === "add" ? handlers.addNew : handlers.edit} />
                </div>
            </div>
            <Modal show={showDeleteExpenseModal} onHide={() => setShowDeleteExpenseModal(false)}>
                <Modal.Header><h5>Delete Expense</h5></Modal.Header>
                <Modal.Body><h6>Do you want to delete this expense?</h6></Modal.Body>
                <Modal.Footer>
                    <div className="footer-modal">
                        <Button variant="danger" style={{ marginRight: "15px" }} onClick={handlers.delete}>Yes</Button>
                        <Button onClick={() => setShowDeleteExpenseModal(false)}>No</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AdminExpenses