import {useState} from "react";
import Notification from "../Hooks/Notification";

const Contact = () => {
    const [formData, setFormData] = useState({name: "", surname: "", email: "", phone: "", message: ""});
    const [notification, setNotification] = useState({ show: false, title: "", message: "" });

    const submitMessageHandler = (e) => {
        e.preventDefault();
        if(formData.name === "" || formData.surname === "" || formData.email === "" || formData.phone === "" || formData.message === ""){
            setNotification({show: true, title: "Form validation", message: "All fields are required"})
        }else{
            setNotification({show: true, title: "Success", message: "Message sent successfully"});
            setFormData({name: "", surname: "", email: "", phone: "", message: ""})
        }
    }

    return (
        <div className={"container"} style={{marginTop: "50px"}}>
            <div className={"contact-page-container"}>
                <div className={"row"}>
                    <div className={"col-md-6"}>
                        <h5>Contact Us</h5>
                        <div className={"contact-form"}>
                            <form onSubmit={submitMessageHandler}>
                                <div className={"row"}>
                                    <div className={"col-md-6"}>
                                        <div className={"form-group"}>
                                            <label>Name</label>
                                            <input type={"text"} className={"form-control"} onChange={(e) => {
                                                setFormData((prev) => ({...prev, name: e.target.value}))
                                            }} value={formData.name}/>
                                        </div>
                                    </div>
                                    <div className={"col-md-6"}>
                                        <div className={"form-group"}>
                                            <label>Surname</label>
                                            <input type={"text"} className={"form-control"} onChange={(e) => {
                                                setFormData((prev) => ({...prev, surname: e.target.value}))
                                            }} value={formData.surname}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={"form-group"}>
                                    <label>Email</label>
                                    <input type={"email"} className={"form-control"} onChange={(e) => {
                                        setFormData((prev) => ({...prev, email: e.target.value}))
                                    }} value={formData.email}/>
                                </div>
                                <div className={"form-group"}>
                                    <label>Phone</label>
                                    <input type={"text"} className={"form-control"} onChange={(e) => {
                                        setFormData((prev) => ({...prev, phone: e.target.value}))
                                    }} value={formData.phone}/>
                                </div>
                                <div className={"form-group"}>
                                    <label>Message</label>
                                    <textarea style={{height: "120px"}} className={"form-control"} onChange={(e) => {
                                        setFormData((prev) => ({...prev, message: e.target.value}))
                                    }} value={formData.message}/>
                                </div>
                                <button className={"orange-button"} style={{width: "100%"}}>Send Message</button>
                            </form>
                        </div>
                    </div>
                    <div className={"col-md-6"}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d822.2130380601361!2d42.697096367782244!3d42.268430012915665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x405c8cefbb3f70fb%3A0x21ce2fcb062fbcc7!2zMjMg4YOS4YOQ4YOa4YOQ4YOZ4YOi4YOY4YOd4YOcIOGDouGDkOGDkeGDmOGDq-GDmOGDoSDhg6UuLCDhg6Xhg6Phg5fhg5Dhg5jhg6Hhg5ggNDYwMA!5e0!3m2!1ska!2sge!4v1696575551908!5m2!1ska!2sge"
                            width="100%" className={"contact-page-map"} height="480" style={{border: "0"}} allowFullScreen="" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
            <Notification title={notification.title} message={notification.message} show={notification.show} setShow={setNotification} />
        </div>
    )
}

export default Contact