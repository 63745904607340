const AboutUs = () => {
    return (
        <div className="about-us-banner">
            <div className="container">
                <h3 className="section-title" style={{color: "white", paddingTop: "50px"}}>About Us</h3>
                <p className="about-us-text">The company <strong>„CAR RENT IN GEORGIA”</strong> was created in 2014. It has been successfully functioning at auto rental and tourism
                    market in Georgia.Our company is certified, developed company. It’s main task is to produce mostly convenient products and to gain
                    trust of the clients. We offer vehicles to hire in Tbilisi and in whole Georgia.
                </p>
                <p className="about-us-text">
                    Autopark is at your disposal - You can use the medium, business and economy class vehicles, SUVs and minivans. All of
                    these vehicles are in excellent technical conditions. Autopark has been periodically filling by the vehicles of different mark,
                    model, color and composition.
                </p>
                <p className="about-us-text">
                    In our service you will get not only the insured and technically settled vehicles, but also a wide range of free technical
                    services and additional services. If you prefer not to drive the hired car by yourselves we are ready to offer you the following
                    service – to hire the car with the experienced driver.
                </p>

                <p className="about-us-text">You can get or leave the car in any area and territory of Georgia - Hotels, International Airports and etc.</p>

                <p className="about-us-text">
                    <strong>„CAR RENT IN GEORGIA”</strong> Managers will explain the rental conditions and assist you to select the optimal tariff and
                    the most suitable vehicle for you, offer you the information about the rent price and time schedule.
                </p>

                <p className="about-us-text"><strong>„CAR RENT IN GEORGIA”</strong> offers full package of services – tours in Georgia</p>
            </div>
        </div>
    )
}

export default AboutUs