import { useEffect, useState } from "react"
import { Button } from "react-bootstrap";

const Characteristics = ({ category, setFormData, formData, setSteps, days, amount }) => {
    const [priceSum, setPriceSum] = useState(0);
    const [value, setValue] = useState({
        tents2x: 0, tents3x: 0, sleepingBag: 0, sleepingBag3x: 0, paralon: 0, bikeHalmet: 0, mopedHalmet: 0, rainCoat: 0
    });

    useEffect(() => {
        setFormData((prev) => ({ ...prev, priceSum: amount + priceSum }))
    }, [priceSum])

    return (
        <div className="add-car-characteristics-list" style={{ marginTop: "20px" }}>
            <div style={{display: "flex", alignItems: "center"}}>
                <p style={{ textAlign: "left", fontSize: "19px", fontWeight: "bold", marginBottom: "12px" }}>OPTIONS - €</p><p style={{fontSize: "19px", fontWeight: "bold", marginBottom: "12px"}}>{priceSum}</p>
            </div>
            <div className="checkbox-list">
                <div className="row" style={{ width: "100%" }}>
                    {(category === "Car" || category === "Minivan") && <div className="form-group checkboxes">
                        <div className="options-name">
                            <img src="http://www.carrentingeorgia.ge/administrator/components/com_vikrentcar/resources/1route.png" alt="gps" />
                            <label htmlFor="gps-system">GPS (5€)</label>
                        </div>
                        <input
                            type="checkbox"
                            id="gps-system"
                            onChange={(e) => {
                                setFormData((prev) => ({ ...prev, gps: e.target.checked }));
                                if (e.target.checked) {
                                    setPriceSum((prev) => prev + Math.round(5 * days));
                                } else {
                                    setPriceSum((prev) => prev - Math.round(5 * days));
                                }
                            }}
                            defaultChecked={[formData].some(i => i.gps === true)}
                        />
                    </div>}
                    {(category === "Car" || category === "Minivan") && <div className="form-group checkboxes">
                        <div className="options-name">
                            <img src="http://www.carrentingeorgia.ge/administrator/components/com_vikrentcar/resources/1seat-belt.png" alt="gps" />
                            <label>BABY SET (5€)</label>
                        </div>
                        <input
                            type="checkbox"
                            onChange={(e) => {
                                setFormData((prev) => ({ ...prev, babySet: e.target.checked }));
                                if (e.target.checked) {
                                    setPriceSum((prev) => prev + Math.round(5 * days));
                                } else {
                                    setPriceSum((prev) => prev - Math.round(5 * days));
                                }
                            }}
                            defaultChecked={[formData].some(i => i.babySet === true)}
                        />
                    </div>}
                    {(category === "Car" || category === "Minivan") && <div className="form-group checkboxes">
                        <div className="options-name">
                            <img src="http://www.carrentingeorgia.ge/administrator/components/com_vikrentcar/resources/1car-with-luggage-on-the-roof-rack.png" alt="gps" />
                            <label>TOP BAG (10€)</label>
                        </div>
                        <input
                            type="checkbox"
                            onChange={(e) => {
                                setFormData((prev) => ({ ...prev, topBag: e.target.checked }));
                                if (e.target.checked) {
                                    setPriceSum((prev) => prev + Math.round(10 * days));
                                } else {
                                    setPriceSum((prev) => prev - Math.round(10 * days));
                                }
                            }}
                            defaultChecked={[formData].some(i => i.topBag === true)}
                        />
                    </div>}
                    {(category === "Car" || category === "Minivan") && <div className="form-group checkboxes">
                        <div className="options-name">
                            <img src="http://www.carrentingeorgia.ge/administrator/components/com_vikrentcar/resources/mp3-symbol-with-disc-and-musical-note.png" alt="gps" />
                            <label>MP3 CONNECTOR (0€)</label>
                        </div>
                        <input
                            type="checkbox"
                            onChange={(e) => {
                                setFormData((prev) => ({ ...prev, mp3Connector: e.target.checked }))
                            }}
                            defaultChecked={[formData].some(i => i.mp3Connector === true)}
                        />
                    </div>}

                    {(category === "Car" || category === "Minivan") && <div className="form-group checkboxes">
                        <div className="options-name">
                            <img src="http://www.carrentingeorgia.ge/administrator/components/com_vikrentcar/resources/car-with-roof-rack.png" alt="gps" />
                            <label>SKY CAR RACK (8€)</label>
                        </div>
                        <input
                            type="checkbox"
                            onChange={(e) => {
                                setFormData((prev) => ({ ...prev, skyCarRack: e.target.checked }));
                                if (e.target.checked) {
                                    setPriceSum((prev) => prev + Math.round(8 * days));
                                } else {
                                    setPriceSum((prev) => prev - Math.round(8 * days));
                                }
                            }}
                            defaultChecked={[formData].some(i => i.skyCarRack === true)}
                        />
                    </div>}
                    {(category === "Car" || category === "Minivan") && <div className="form-group checkboxes">
                        <div className="options-name">
                            <img src="http://www.carrentingeorgia.ge/administrator/components/com_vikrentcar/resources/1wifi.png" alt="gps" />
                            <label>WIFI PACK 1GB (15€)</label>
                        </div>
                        <input
                            type="checkbox"
                            onChange={(e) => {
                                setFormData((prev) => ({ ...prev, wifiPack: e.target.checked }));
                                if (e.target.checked) {
                                    setPriceSum((prev) => prev + Math.round(15 * days));
                                } else {
                                    setPriceSum((prev) => prev - Math.round(15 * days));
                                }
                            }}
                            defaultChecked={[formData].some(i => i.wifiPack === true)}
                        />
                    </div>}
                    {(category === "Car" || category === "Minivan") && <div className="form-group checkboxes">
                        <div className="options-name">
                            <img src="http://www.carrentingeorgia.ge/administrator/components/com_vikrentcar/resources/stove.png" alt="gps" />
                            <label>MSR SUPERFLY (4€)</label>
                        </div>
                        <input
                            type="checkbox"
                            onChange={(e) => {
                                setFormData((prev) => ({ ...prev, msrSuperFly: e.target.checked }));
                                if (e.target.checked) {
                                    setPriceSum((prev) => prev + Math.round(4 * days));
                                } else {
                                    setPriceSum((prev) => prev - Math.round(4 * days));
                                }
                            }}
                            defaultChecked={[formData].some(i => i.msrSuperFly === true)}
                        />
                    </div>}
                    {(category === "Car" || category === "Minivan") && <div className="form-group checkboxes">
                        <div className="options-name">
                            <img src="http://www.carrentingeorgia.ge/administrator/components/com_vikrentcar/resources/fishing-man.png" alt="gps" />
                            <label>FISHING ROD (3€)</label>
                        </div>
                        <input
                            type="checkbox"
                            onChange={(e) => {
                                setFormData((prev) => ({ ...prev, fishingRod: e.target.checked }));
                                if (e.target.checked) {
                                    setPriceSum((prev) => prev + Math.round(3 * days));
                                } else {
                                    setPriceSum((prev) => prev - Math.round(3 * days));
                                }
                            }}
                            defaultChecked={[formData].some(i => i.fishingRod === true)}
                        />
                    </div>}
                    {(category === "Car" || category === "Minivan") && <div className="form-group checkboxes">
                        <div className="options-name">
                            <img src="http://www.carrentingeorgia.ge/administrator/components/com_vikrentcar/resources/building.png" alt="gps" />
                            <label>DRIVER IN CITY (25€)</label>
                        </div>
                        <input
                            type="checkbox"
                            onChange={(e) => {
                                setFormData((prev) => ({ ...prev, driverInCity: e.target.checked }));
                                if (e.target.checked) {
                                    setPriceSum((prev) => prev + Math.round(25 * days));
                                } else {
                                    setPriceSum((prev) => prev - Math.round(25 * days));
                                }
                            }}
                            defaultChecked={[formData].some(i => i.driverInCity === true)}
                        />
                    </div>}
                    {(category === "Car" || category === "Minivan") && <div className="form-group checkboxes">
                        <div className="options-name">
                            <img src="http://www.carrentingeorgia.ge/administrator/components/com_vikrentcar/resources/tree.png" alt="gps" />
                            <label>DRIVER OUT OF CITY (35€)</label>
                        </div>
                        <input
                            type="checkbox"
                            onChange={(e) => {
                                setFormData((prev) => ({ ...prev, driverOutOfCity: e.target.checked }))
                                if (e.target.checked) {
                                    setPriceSum((prev) => prev + Math.round(35 * days));
                                } else {
                                    setPriceSum((prev) => prev - Math.round(35 * days));
                                }
                            }}
                            defaultChecked={[formData].some(i => i.driverOutOfCity === true)}
                        />
                    </div>}

                    <div className="form-group checkboxes">
                        <div className="options-name">
                            <img src="http://www.carrentingeorgia.ge/administrator/components/com_vikrentcar/resources/camping-tent.png" alt="gps" />
                            <label>TENTS 2X (5€)</label>
                        </div>
                        <input
                            type="number"
                            className="form-control"
                            min={"0"}
                            style={{ maxWidth: "50px" }}
                            onChange={(e) => {
                                setFormData((prev) => ({ ...prev, tents2x: e.target.value }));
                                setPriceSum((prev) => {
                                    const v = value.tents2x * Math.round(5 * days);
                                    const p = e.target.value * Math.round(5 * days);
                                    return prev - v + p;
                                });
                                setValue((prev) => ({ ...prev, tents2x: e.target.value }));
                            }}
                            value={formData.tents2x}
                        />
                    </div>
                    <div className="form-group checkboxes">
                        <div className="options-name">
                            <img src="http://www.carrentingeorgia.ge/administrator/components/com_vikrentcar/resources/camping-tent.png" alt="gps" />
                            <label>TENTS 3X (7€)</label>
                        </div>

                        <input
                            type="number"
                            className="form-control"
                            style={{ maxWidth: "50px" }}
                            min={"0"}
                            onChange={(e) => {
                                setFormData((prev) => ({ ...prev, tents3x: e.target.value }))
                                setPriceSum((prev) => {
                                    const v = value.tents3x * Math.round(7 * days);
                                    const p = e.target.value * Math.round(7 * days);
                                    return prev - v + p;
                                });
                                setValue((prev) => ({ ...prev, tents3x: e.target.value }));
                            }}
                            value={formData.tents3x}
                        />
                    </div>
                    {(category === "Car" || category === "Minivan") && <div className="form-group checkboxes">
                        <div className="options-name">
                            <img src="http://www.carrentingeorgia.ge/administrator/components/com_vikrentcar/resources/sleeping-bags.png" alt="gps" />
                            <label>SLEEPING BAG (3€)</label>
                        </div>
                        <input
                            type="number"
                            min={"0"}
                            style={{ maxWidth: "50px" }}
                            className="form-control"
                            onChange={(e) => {
                                setFormData((prev) => ({ ...prev, sleepingBag: e.target.value }))
                                setPriceSum((prev) => {
                                    const v = value.sleepingBag * Math.round(3 * days);
                                    const p = e.target.value * Math.round(3 * days);
                                    return prev - v + p;
                                });
                                setValue((prev) => ({ ...prev, sleepingBag: e.target.value }));
                            }}
                            value={formData.sleepingBag}
                        />
                    </div>}
                    <div className="form-group checkboxes">
                        <div className="options-name">
                            <img src="http://www.carrentingeorgia.ge/administrator/components/com_vikrentcar/resources/sleeping-bag22.png" alt="gps" />
                            <label>SLEEPING BAG 3X (4€)</label>
                        </div>
                        <input
                            type="number"
                            min={"0"}
                            style={{ maxWidth: "50px" }}
                            className="form-control"
                            onChange={(e) => {
                                setFormData((prev) => ({ ...prev, sleepingBag3x: e.target.value }))
                                setPriceSum((prev) => {
                                    const v = value.sleepingBag3x * Math.round(4 * days);
                                    const p = e.target.value * Math.round(4 * days);
                                    return prev - v + p;
                                });
                                setValue((prev) => ({ ...prev, sleepingBag3x: e.target.value }));
                            }}
                            value={formData.sleepingBag3x}
                        />
                    </div>
                    <div className="form-group checkboxes">
                        <div className="options-name">
                            <img src="http://www.carrentingeorgia.ge/administrator/components/com_vikrentcar/resources/1sleeping-bag.png" alt="gps" />
                            <label>PARALON (2€)</label>
                        </div>
                        <input
                            className="form-control"
                            min={"0"}
                            style={{ maxWidth: "50px" }}
                            type="number"
                            onChange={(e) => {
                                setFormData((prev) => ({ ...prev, paralon: e.target.value }))
                                setPriceSum((prev) => {
                                    const v = value.paralon * Math.round(2 * days);
                                    const p = e.target.value * Math.round(2 * days);
                                    return prev - v + p;
                                });
                                setValue((prev) => ({ ...prev, paralon: e.target.value }));
                            }}
                            value={formData.paralon}
                        />
                    </div>
                    {(category === "Moped" || category === "Bike") && <div className="form-group checkboxes">
                        <div className="options-name">
                            <img src="http://www.carrentingeorgia.ge/administrator/components/com_vikrentcar/resources/jacket.png" alt="gps" />
                            <label>Rain Coat (1€)</label>
                        </div>
                        <input
                            className="form-control"
                            min={"0"}
                            style={{ maxWidth: "50px" }}
                            type="number"
                            onChange={(e) => {
                                setFormData((prev) => ({ ...prev, rainCoat: e.target.value }))
                                setPriceSum((prev) => {
                                    const v = value.rainCoat * Math.round(1 * days);
                                    const p = e.target.value * Math.round(1 * days);
                                    return prev - v + p;
                                });
                                setValue((prev) => ({ ...prev, rainCoat: e.target.value }));
                            }}
                            value={formData.rainCoat}
                        />
                    </div>}
                    {category === "Moped" && <div className="form-group checkboxes">
                        <div className="options-name">
                            <img src="http://www.carrentingeorgia.ge/administrator/components/com_vikrentcar/resources/biker.png" alt="gps" />
                            <label>Mopad Helmet (5€)</label>
                        </div>
                        <input
                            className="form-control"
                            min={"0"}
                            style={{ maxWidth: "50px" }}
                            type="number"
                            onChange={(e) => {
                                setFormData((prev) => ({ ...prev, mopedHalmet: e.target.value }))
                                setPriceSum((prev) => {
                                    const v = value.mopedHalmet * Math.round(5 * days);
                                    const p = e.target.value * Math.round(5 * days);
                                    return prev - v + p;
                                });
                                setValue((prev) => ({ ...prev, mopedHalmet: e.target.value }));
                            }}
                            value={formData.mopedHalmet}
                        />
                    </div>}
                    {category === "Bike" && <div className="form-group checkboxes">
                        <div className="options-name">
                            <img src="http://www.carrentingeorgia.ge/administrator/components/com_vikrentcar/resources/biker_1.png" alt="gps" />
                            <label>Bika Halmet (10€)</label>
                        </div>
                        <input
                            type="checkbox"
                            onChange={(e) => {
                                setFormData((prev) => ({ ...prev, bikeHalmet: e.target.checked }))
                                if (e.target.checked) {
                                    setPriceSum((prev) => prev + Math.round(10 * days));
                                } else {
                                    setPriceSum((prev) => prev - Math.round(10 * days));
                                }
                            }}
                            defaultChecked={[formData].some(i => i.bikeHalmet === true)}
                        />
                    </div>}
                </div>
            </div>
            <div className="row">
                <div className="col-md-3"><Button onClick={() => setSteps(1)} style={{ width: "100%", marginTop: "20px" }} variant="dark">Back</Button></div>
                <div className="col-md-6"></div>
                <div className="col-md-3"><button className="start-booking-button" style={{ marginTop: "20px" }} onClick={() => {
                    setFormData((prev) => ({ ...prev, priceSum: prev.priceSum }))
                    setSteps(3);
                }}>Next step</button></div>
            </div>
        </div>
    )
}

export default Characteristics