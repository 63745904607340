import { useEffect, useState } from "react";
import './addCarsModal.css'
import axios from "axios";
const { Modal } = require("react-bootstrap")

const AddBookingModal = ({ show, hide, formData, setFormData, action, onSubmit }) => {
    const [pickupLocations, setPickupLocations] = useState([]);
    const [dropOffLocations, setDropOffLocations] = useState([]);
    const [notification, setNotification] = useState({ show: false, title: "", message: "" });
    const [active, setActive] = useState({});
    const [cars, setCars] = useState([]);
    console.log(formData)
    useEffect(() => {
        axios('/api/cars?page=1&limit=200')
            .then((res) => {
                const data = res.data.data;
                setCars([...data]);
            })
            .catch((err) => {
                const data = err.response.data;
                setNotification({ show: true, title: data.description, message: data.message })
            })
    }, [])

    return (
        show && <Modal show={show} onHide={() => hide(false)} size={"xl"} centered>
            <Modal.Header><h4>{action === "add" ? "Add new order" : "Edit order"}</h4></Modal.Header>
            <Modal.Body>
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Ordered Car Id</label>
                                <select
                                    onChange={(e) => {
                                        setFormData((prev) => ({ ...prev, orderedCarId: e.target.value }));
                                        const car = cars.filter((c) => c.id == e.target.value);
                                        setDropOffLocations([...car[0].dropOffLocations]);
                                        setPickupLocations([...car[0].pickupLocations]);
                                        setFormData((prev) => ({ ...prev, id: car[0].id, mark: car[0].mark, model: car[0].model, year: car[0].year }));
                                        setActive({ ...car });
                                    }}
                                    value={formData.orderedCarId}
                                    className="form-control"
                                >
                                    <option>Select Ordered Car</option>
                                    {
                                        cars.map((c, i) => {
                                            return (
                                                <option key={i * 30} value={`${c.id}`}>{`${c.id} ${c.mark} ${c.model} ${c.year}`}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Mark</label>
                                <input
                                    placeholder="Enter Mark"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, mark: e.target.value }))}
                                    value={formData.mark}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Model</label>
                                <input
                                    placeholder="Enter Model"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, model: e.target.value }))}
                                    value={formData.model}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Year</label>
                                <input
                                    placeholder="Enter Year"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, year: e.target.value }))}
                                    value={formData.year}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Pickup Location</label>
                                <select
                                    onChange={(e) => setFormData((prev) => ({ ...prev, pickupLocation: e.target.value }))}
                                    value={formData.pickupLocation}
                                    className="form-control"
                                >
                                    <option>Select Pickup Location</option>
                                    <option value={"Kutaisi Center"}>Kutaisi Center</option>
                                    <option value={"Kutaisi Airport"}>Kutaisi Airport</option>
                                    <option value={"Tbilisi Center"}>Tbilisi Center</option>
                                    <option value={"Tbilisi Airport"}>Tbilisi Airport</option>
                                    <option value={"Batumi Center"}>Batumi Center</option>
                                    <option value={"Batumi Airport"}>Batumi Airport</option>
                                    <option value={"Other"}>At All Other Places</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Pickup Date</label>
                                <input
                                    type="datetime-local"
                                    onChange={(e) => {
                                        const days = (+new Date(formData.dropOffDate) - (+new Date(e.target.value))) / 86400000;
                                        let price = 0;
                                        if (days > 13) {
                                            price = parseInt(days) * parseInt(active[0].priceFour);
                                        } else if (days > 6) {
                                            price = parseInt(days) * parseInt(active[0].priceThree);
                                        } else if (days > 3) {
                                            price = parseInt(days) * parseInt(active[0].priceTwo);
                                        } else if (days > 0) {
                                            price = parseInt(days) * parseInt(active[0].priceOne);
                                        }
                                        console.log(`${price} ${parseInt(days)} ${parseInt(formData.priceOne)}`);
                                        setFormData((prev) => ({ ...prev, pickupDate: e.target.value, priceSum: price }));
                                    }}
                                    value={formData.pickupDate}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Drop Off Location</label>
                                <select
                                    onChange={(e) => setFormData((prev) => ({ ...prev, dropOffLocation: e.target.value }))}
                                    value={formData.dropOffLocation}
                                    className="form-control"
                                >
                                    <option>Select Dropoff Location</option>
                                    <option value={"Kutaisi Center"}>Kutaisi Center</option>
                                    <option value={"Kutaisi Airport"}>Kutaisi Airport</option>
                                    <option value={"Tbilisi Center"}>Tbilisi Center</option>
                                    <option value={"Tbilisi Airport"}>Tbilisi Airport</option>
                                    <option value={"Batumi Center"}>Batumi Center</option>
                                    <option value={"Batumi Airport"}>Batumi Airport</option>
                                    <option value={"Other"}>At All Other Places</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Drop Off Date</label>
                                <input
                                    type="datetime-local"
                                    onChange={(e) => {
                                        const days = (+new Date(e.target.value) - (+new Date(formData.pickupDate))) / 86400000;
                                        let price = 0;
                                        let locationFee = 0;
                                        if (formData.pickupLocation === "Kutaisi Office") locationFee += 0;
                                        if (formData.pickupLocation === "Kutaisi Center") locationFee += 5;
                                        if (formData.pickupLocation === "Kutaisi Airport") locationFee += 15;
                                        if (formData.pickupLocation === "Tbilisi Center") locationFee += 45;
                                        if (formData.pickupLocation === "Tbilisi Airport") locationFee += 55;
                                        if (formData.pickupLocation === "Batumi Center") locationFee += 45;
                                        if (formData.pickupLocation === "Batumi Airport") locationFee += 55;
                                        if (formData.pickupLocation === "Other") locationFee += 70;
                                        if (formData.dropOffLocation === "Kutaisi Office") locationFee += 0;
                                        if (formData.dropOffLocation === "Kutaisi Center") locationFee += 5;
                                        if (formData.dropOffLocation === "Kutaisi Airport") locationFee += 15;
                                        if (formData.dropOffLocation === "Tbilisi Center") locationFee += 45;
                                        if (formData.dropOffLocation === "Tbilisi Airport") locationFee += 55;
                                        if (formData.dropOffLocation === "Batumi Center") locationFee += 45;
                                        if (formData.dropOffLocation === "Batumi Airport") locationFee += 55;
                                        if (formData.dropOffLocation === "Other") locationFee += 70;
                                        const income = (parseInt(days) * parseInt(active.income)) + locationFee;
                                        if (days > 13) {
                                            price = parseInt(days) * parseInt(active[0].priceFour);
                                        } else if (days > 6) {
                                            price = parseInt(days) * parseInt(active[0].priceThree);
                                        } else if (days > 3) {
                                            price = parseInt(days) * parseInt(active[0].priceTwo);
                                        } else if (days > 0) {
                                            price = parseInt(days) * parseInt(active[0].priceOne);
                                        }
                                        price = price + locationFee;
                                        setFormData((prev) => ({ ...prev, dropOffDate: e.target.value, priceSum: price, income: income }));
                                    }}
                                    value={formData.dropOffDate}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Price Sum</label>
                                <input
                                    placeholder="Enter Price Sum"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, priceSum: e.target.value }))}
                                    value={formData.priceSum}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Order Type</label>
                                <select
                                    onChange={(e) => setFormData((prev) => ({ ...prev, orderType: e.target.value }))}
                                    value={formData.orderType}
                                    className="form-control"
                                >
                                    <option>Select Order Type</option>
                                    <option value={"website"}>From Website</option>
                                    <option value={"office"}>From Office</option>
                                    <option value={"external"}>External Orders</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Accept</label>
                                <select
                                    onChange={(e) => setFormData((prev) => ({ ...prev, accept: e.target.value }))}
                                    value={formData.accept}
                                    className="form-control"
                                >
                                    <option value={"No"}>No</option>
                                    <option value={"Yes"}>Yes</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Name</label>
                                <input
                                    placeholder="Enter Name"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))}
                                    value={formData.name}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Surname</label>
                                <input
                                    placeholder="Enter Surname"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, surname: e.target.value }))}
                                    value={formData.surname}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    type="email"
                                    placeholder="Enter Email"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))}
                                    value={formData.email}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Age</label>
                                <input
                                    placeholder="Enter Age"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, age: e.target.value }))}
                                    value={formData.age}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Phone</label>
                                <input
                                    placeholder="Enter Phone"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, phone: e.target.value }))}
                                    value={formData.phone}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Payment Method</label>
                                <select
                                    onChange={(e) => setFormData((prev) => ({ ...prev, paymentMethod: e.target.value }))}
                                    value={formData.paymentMethod}
                                    className="form-control"
                                >
                                    <option>Select Payment Method</option>
                                    <option value={"card"}>With Bank Card</option>
                                    <option value={"cash"}>With Cash</option>
                                    <option value={"paypal"}>With Paypal</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Income</label>
                                <input
                                    placeholder="Enter Income"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, income: e.target.value }))}
                                    value={formData.income}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Note</label>
                                <textarea
                                    placeholder="Enter Note"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, note: e.target.value }))}
                                    value={formData.note}
                                    className="form-control"
                                    style={{height: "122px"}}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="tents-2x">TENTS 2X</label>
                                <input
                                    type="number"
                                    min={"0"}
                                    id="tents-2x"
                                    className="form-control"
                                    onChange={(e) => {
                                        setFormData((prev) => ({ ...prev, tents2x: e.target.value }))
                                    }}
                                    value={formData.tents2x}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="tents-3x">TENTS 3X</label>
                                <input
                                    type="number"
                                    min={"0"}
                                    id="tents-3x"
                                    className="form-control"
                                    onChange={(e) => {
                                        setFormData((prev) => ({ ...prev, tents3x: e.target.value }))
                                    }}
                                    value={formData.tents3x}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="sleeping-bag">SLEEPING BAG</label>
                                <input
                                    type="number"
                                    min={"0"}
                                    id="sleeping-bag"
                                    className="form-control"
                                    onChange={(e) => {
                                        setFormData((prev) => ({ ...prev, sleepingBag: e.target.value }))
                                    }}
                                    value={formData.sleepingBag}
                                />

                            </div>
                            <div className="form-group">
                                <label htmlFor="sleeping-bag-3x">SLEEPING BAG 3X</label>
                                <input
                                    type="number"
                                    id="sleeping-bag-3x"
                                    min={"0"}
                                    className="form-control"
                                    onChange={(e) => {
                                        setFormData((prev) => ({ ...prev, sleepingBag3x: e.target.value }))
                                    }}
                                    value={formData.sleepingBag3x}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="paralon">PARALON</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    min={"0"}
                                    id="paralon"
                                    onChange={(e) => {
                                        setFormData((prev) => ({ ...prev, paralon: e.target.value }))
                                    }}
                                    value={formData.paralon}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="bike-halmet">RAIN COAT</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    min={"0"}
                                    id="bike-halmet"
                                    onChange={(e) => {
                                        setFormData((prev) => ({ ...prev, rainCoat: e.target.value }))
                                    }}
                                    value={formData.rainCoat}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="moped-halmet">MOPED HALMET</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    min={"0"}
                                    id="moped-halmet"
                                    onChange={(e) => {
                                        setFormData((prev) => ({ ...prev, mopedHalmet: e.target.value }))
                                    }}
                                    value={formData.mopedHalmet}
                                />
                            </div>
                            <div className="add-car-characteristics-list">
                                <p style={{ marginBottom: "0px" }}>Characteristics</p>
                                <div className="checkbox-list">
                                    <div className="form-group checkboxes">
                                        <input
                                            type="checkbox"
                                            id="gps-system"
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, gps: e.target.checked }))
                                            }}
                                            defaultChecked={[formData].some(i => i.gps === true)}
                                        />
                                        <label htmlFor="gps-system">GPS</label>
                                    </div>
                                    <div className="form-group checkboxes">
                                        <input
                                            type="checkbox"
                                            id="baby-set"
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, babySet: e.target.checked }))
                                            }}
                                            defaultChecked={[formData].some(i => i.babySet === true)}
                                        />
                                        <label htmlFor="baby-set">BABY SET</label>
                                    </div>
                                    <div className="form-group checkboxes">
                                        <input
                                            type="checkbox"
                                            id="top-bag"
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, topBag: e.target.checked }))
                                            }}
                                            defaultChecked={[formData].some(i => i.topBag === true)}
                                        />
                                        <label htmlFor="top-bag">TOP BAG</label>
                                    </div>
                                    <div className="form-group checkboxes">
                                        <input
                                            type="checkbox"
                                            id="mp3-connector"
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, mp3Connector: e.target.checked }))
                                            }}
                                            defaultChecked={[formData].some(i => i.mp3Connector === true)}
                                        />
                                        <label htmlFor="mp3-connector">MP3 CONNECTOR</label>
                                    </div>
                                    <div className="form-group checkboxes">
                                        <input
                                            type="checkbox"
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, skyCarRack: e.target.checked }))
                                            }}
                                            id="sky-car-rack"
                                            defaultChecked={[formData].some(i => i.skyCarRack === true)}
                                        />
                                        <label htmlFor="sky-car-rack">SKY CAR RACK</label>
                                    </div>
                                    <div className="form-group checkboxes">
                                        <input
                                            type="checkbox"
                                            id="wifi-pack"
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, wifiPack: e.target.checked }))
                                            }}
                                            defaultChecked={[formData].some(i => i.wifiPack === true)}
                                        />
                                        <label htmlFor="wifi-pack">WIFI PACK 1GB</label>
                                    </div>
                                    <div className="form-group checkboxes">
                                        <input
                                            type="checkbox"
                                            id="msr-super-fly"
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, msrSuperFly: e.target.checked }))
                                            }}
                                            defaultChecked={[formData].some(i => i.msrSuperFly === true)}
                                        />
                                        <label htmlFor="msr-super-fly">MSR SUPERFLY</label>
                                    </div>
                                    <div className="form-group checkboxes">
                                        <input
                                            type="checkbox"
                                            id="fishing-rod"
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, fishingRod: e.target.checked }))
                                            }}
                                            defaultChecked={[formData].some(i => i.fishingRod === true)}
                                        />
                                        <label htmlFor="fishing-rod">FISHING ROD</label>
                                    </div>
                                    <div className="form-group checkboxes">
                                        <input
                                            type="checkbox"
                                            id="driver-in-city"
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, driverInCity: e.target.checked }))
                                            }}
                                            defaultChecked={[formData].some(i => i.driverInCity === true)}
                                        />
                                        <label htmlFor="driver-in-city">DRIVER IN CITY</label>
                                    </div>
                                    <div className="form-group checkboxes">
                                        <input
                                            type="checkbox"
                                            id="driver-out-of-city"
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, driverOutOfCity: e.target.checked }))
                                            }}
                                            defaultChecked={[formData].some(i => i.driverOutOfCity === true)}
                                        />
                                        <label htmlFor="driver-out-of-city">DRIVER OUT OF CITY</label>
                                    </div>
                                    <div className="form-group checkboxes">
                                        <input
                                            type="checkbox"
                                            id="driver-out-of-city"
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, bikeHalmet: e.target.checked }))
                                            }}
                                            defaultChecked={[formData].some(i => i.bikeHalmet === true)}
                                        />
                                        <label htmlFor="driver-out-of-city">BIKE HALMET</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12" style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>
                            <button className="orange-button" style={{ width: "50%" }}>{action === "add" ? "Add New" : "Save"}</button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default AddBookingModal