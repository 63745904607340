import './DataTable.css';
import { Table } from "react-bootstrap";
import { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import OutsideClickHandler from 'react-outside-click-handler';

const DataTable = ({
    data,
    searchPlaceHolder,
    withoutSearchButton,
    searchHandler,
    title,
    actionButtons,
    headers,
    limitControl,
    setLimit,
    limit,
    showParams,
    pageChanged,
    hideSelects,
    setPageChanged,
    total,
    rowActions,
    rowActionsList,
    setActiveRow,
    withSelectFilter,
    selects,
    dateRange,
    setDate,
    date,
    selectValue
}) => {
    const [keyword, setKeyword] = useState("");
    const [rowActionsDropdown, setRowActionsDropdown] = useState({
        id: "",
        show: false
    })

    return (
        <>
            <h4 className={"section-title"}>{title}</h4>
            <div className={"data-table-header"}>
                <div className={"data-table-search-box"}>
                    <input
                        type={"search"}
                        className={"form-control"}
                        title={withoutSearchButton ? "Press 'Enter' to start searching" : ""}
                        placeholder={searchPlaceHolder ? searchPlaceHolder : "Please enter keyword"}
                        onChange={(e) => setKeyword(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) searchHandler(keyword);
                        }}
                    />
                    <button className={"orange-button"} onClick={() => searchHandler(keyword)}>Search</button>
                </div>
                {

                    !hideSelects && <div className={"select-filters"}>
                        {withSelectFilter &&
                            selects.map((s) => {
                                return (
                                    <select key={s.id} className={"form-control"} value={selectValue}
                                        onChange={(e) => s.action(e.target.value)}>
                                        <option>{s.label}</option>
                                        {
                                            s.options.map((o) => {
                                                return (
                                                    <option key={o.id} value={o.value}>{o.label}</option>
                                                )
                                            })
                                        }
                                    </select>
                                )
                            })
                        }
                        {dateRange && <div className='form-dates'>
                            <div className={"date-selects"} style={{ marginLeft: "8px" }}>
                                <input type={"date"} className={"form-control"} value={date.from} onChange={(e) => setDate((prev) => {
                                    const date = new Date(e.target.value);
                                    const year = date.getFullYear();
                                    const month = String(date.getMonth() + 1).padStart(2, '0');
                                    const day = String(date.getDate()).padStart(2, '0');
                                    return { ...prev, from: `${year}-${month}-${day}` }
                                })} />
                            </div>
                            <div className={"date-selects"} style={{ marginLeft: "8px" }}>
                                <input type={"date"} className={"form-control"} value={date.to} onChange={(e) => setDate((prev) => {
                                    const date = new Date(e.target.value);
                                    const year = date.getFullYear();
                                    const month = String(date.getMonth() + 1).padStart(2, '0');
                                    const day = String(date.getDate()).padStart(2, '0');
                                    return { ...prev, to: `${year}-${month}-${day}` }
                                })} />
                            </div>
                            <span onClick={() => {
                                const d = new Date();
                                const year = d.getFullYear();
                                const month = String(d.getMonth() + 1).padStart(2, '0');
                                const day = String(d.getDate()).padStart(2, '0');
                                setDate({ from: "", to: `${year}-${month}-${day}` })
                            }} className='clear-button'>Clear</span>
                        </div>}
                    </div>
                }
                {
                    actionButtons && (
                        <div className={"database-header-actions"}>
                            {limitControl && <select className={"form-control"} value={limit} title={"Items per page"}
                                style={{ width: "80px" }}
                                onChange={(e) => setLimit(e.target.value)}>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>}
                            {actionButtons.map((buttons, k) => {
                                return (<button key={k * 15} className={"orange-button data-table-action-buttons"}
                                    style={{ background: buttons.background ? buttons.background : "#0a56f7" }}
                                    onClick={buttons.action}>
                                    {buttons.name}
                                </button>)
                            })}
                        </div>
                    )
                }
            </div>
            <div className={"data-table-container"}>
                <Table responsive={true}>
                    <thead>
                        <tr>
                            {headers.map((h, k) => {
                                return <th key={k * 110}>{h}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.length > 0 ? (
                                data.map((d) => {
                                    return (
                                        <tr key={d.id}>
                                            {
                                                showParams.map((p, l) => {
                                                    return (
                                                        p.type == "boolean" ? (
                                                            <td key={l * 1000}>{d[p.name] === true ? "Yes" : "No"}</td>
                                                        ) : (
                                                            <td key={l * 1000}>{p.type === "date" ? d[p.name].replace("T", " ") : d[p.name]}</td>
                                                        )
                                                    )
                                                })
                                            }
                                            {
                                                rowActions && (
                                                    <td>
                                                        <BsThreeDotsVertical className={"actions-dropdown"}
                                                            onClick={() => setRowActionsDropdown({
                                                                id: d.id,
                                                                show: true
                                                            })} />
                                                        {(rowActionsDropdown.show && rowActionsDropdown.id === d.id) &&
                                                            <OutsideClickHandler onOutsideClick={() => {
                                                                setRowActionsDropdown({ id: "0", show: false })
                                                            }}>
                                                                <div className={"actions-dropdown-box"}>
                                                                    <ul className={"actions-list"}>
                                                                        {
                                                                            rowActionsList.map((a) => {
                                                                                return (
                                                                                    <li onClick={() => {
                                                                                        setActiveRow({ ...d });
                                                                                        setRowActionsDropdown({
                                                                                            id: "0",
                                                                                            show: false
                                                                                        })
                                                                                        a.action({ ...d });
                                                                                    }} key={a.id}>{a.name}</li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </OutsideClickHandler>}
                                                    </td>
                                                )
                                            }
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td colSpan={headers.length}>No records</td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </div>
            <div className={"data-table-footer"}>
                <div className="pagination-container">
                    {pageChanged > 1 && <span className="next-page-button" onClick={() => {
                        setPageChanged((prev) => (prev - 1))
                    }}>
                        {'<<'}
                    </span>}
                    {
                        Array.from(Array(Math.ceil(total / 10)).keys())
                            .map((p, k) => {
                                if (p < pageChanged - 2 || p + 1 > pageChanged + 2) {
                                    return
                                } else {
                                    return (<span key={k * 15}
                                        className={(pageChanged === p + 1) ? "active-page-number" : undefined}
                                        onClick={() => {
                                            setPageChanged(p + 1)
                                        }
                                        }>
                                        {p + 1}
                                    </span>)
                                }
                            })
                    }
                    {pageChanged < Math.ceil(total / 10) && <span className="next-page-button" onClick={() => {
                        setPageChanged((prev) => (prev + 1))
                    }}>
                        {'>>'}
                    </span>}
                </div>
                <p className={"table-total-count"}><strong>Total:</strong> {total}</p>
            </div>
        </>
    )
}

export default DataTable