const AdditionalServices = () => {
    return (
        <>
            <h3 className="section-title">Additional services</h3>
            <div className="services-list row" style={{marginBottom: "70px"}}>
                <div className="col-md-3">
                    <div className='service-image-box' style={{height: "120px"}}>
                        <img style={{width: "100px"}} className="services-icon" src="https://crg.ge/static/icons/money.webp" alt='services' />
                    </div>
                    <p className='services-slogan'>NO DEPOSIT FOR MOST CARS</p>
                </div>
                <div className="col-md-3">
                    <div className='service-image-box' style={{height: "120px"}}>
                        <img style={{width: "100px"}} className="services-icon" src="https://crg.ge/static/icons/parking.webp" alt='services' />
                    </div>
                    <p className='services-slogan'>FREE PARKING</p>
                </div>
                <div className="col-md-3">
                    <div className='service-image-box' style={{height: "120px"}}>
                        <img style={{width: "100px"}} className="services-icon" src="https://crg.ge/static/icons/protection.webp" alt='services' />
                    </div>
                    <p className='services-slogan'>100% SUPERCASCO INSURANCE</p>
                </div>
                <div className="col-md-3">
                    <div className='service-image-box' style={{height: "120px"}}>
                        <img style={{width: "100px"}} className="services-icon" src="https://crg.ge/static/icons/speed.webp" alt='services' />
                    </div>
                    <p className='services-slogan'>UNLIMITED MILLAGE</p>
                </div>
            </div>
        </>
    )
}

export default AdditionalServices