import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { FcPrevious, FcNext } from 'react-icons/fc';
import Characteristics from "../Components/Characteristics";
import Notification from "../Hooks/Notification";
import { Button, Modal, Table } from "react-bootstrap";
import OrderDetailsModal from "../Components/OrderDetailsModal";

const CarPage = ({ }) => {
    const date = new Date();
    const [carPrice, setCarPrice] = useState(0);
    const today = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}T00:00`;
    const [minDate, setMinDate] = useState('');
    const location = useLocation();
    const [amount, setAmount] = useState(0);
    const [days, setDays] = useState(0);
    const [showDetails, setShowDetails] = useState(false);
    const [notification, setNotification] = useState({ show: false, title: "", message: "" });
    const id = location.pathname.slice(5);
    const [showTerms, setShowTerms] = useState(false);
    const [data, setData] = useState({});
    const [locationPrice, setLocationPrice] = useState(0);
    const [agreed, setAgreed] = useState(false);
    const [index, setIndex] = useState(0);
    const [orderId, setOrderId] = useState(0);
    const [formData, setFormData] = useState({
        pickupLocation: "", pickupDate: "", dropOffDate: "", dropOffLocation: "", gps: false, babySet: false, topBag: false, mp3Connector: false, skyCarRack: false,
        wifiPack: false, tents2x: 0, tents3x: 0, sleepingBag: 0, sleepingBag3x: 0, paralon: 0, msrSuperFly: false, fishingRod: false, bikeHalmet: false, mopedHalmet: false, rainCoat: false,
        driverInCity: false, driverOutOfCity: false, name: "", surname: "", email: "", age: "", note: "", phone: "", paymentMethod: "", priceSum: 0, orderType: "website", income: ""
    });
    const [steps, setSteps] = useState(1);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const queryObject = {};
        for (const [key, value] of queryParams.entries()) {
            queryObject[key] = value;
        }

        const { pickup, dropoff, start, end } = queryObject;
        const sDate = new Date(parseInt(start));
        const eDate = new Date(parseInt(end));
        const strDate = `${sDate.getFullYear() + 1 < 10 ? `0${sDate.getFullYear()}` : sDate.getFullYear()}-${sDate.getMonth() + 1 < 10 ? `0${sDate.getMonth() + 1}` : sDate.getMonth() + 1}-${sDate.getDate() < 10 ? `0${sDate.getDate()}` : sDate.getDate()}T${sDate.getHours()}:${sDate.getMinutes()}`;
        const enDate = `${eDate.getFullYear() + 1 < 10 ? `0${eDate.getFullYear()}` : eDate.getFullYear()}-${eDate.getMonth() + 1 < 10 ? `0${eDate.getMonth() + 1}` : eDate.getMonth() + 1}-${eDate.getDate() < 10 ? `0${eDate.getDate()}` : eDate.getDate()}T${sDate.getHours()}:${sDate.getMinutes()}`;

        setFormData((prev) => ({ ...prev, pickupDate: strDate, dropOffDate: enDate, pickupLocation: pickup, dropOffLocation: dropoff }));

        axios(`/api/cars/${id}`)
            .then((res) => {
                const data = res.data.data[0];
                setData({ ...data });
                setFormData((prev) => ({ ...prev, mark: data.mark, model: data.model, year: data.year, income: data.income, locationPrice: locationPrice }))

                if (typeof start !== "undefined" && typeof end !== "undefined") {
                    const startDate = +new Date(strDate);
                    const endDate = +new Date(enDate);
                    const daysCount = (endDate - startDate) / 86400000;
                    const hours = (endDate - startDate) / 3600000;
                    let locationFee = 0;
                    if (formData.pickupLocation === "Kutaisi Office") locationFee += 0;
                    if (formData.pickupLocation === "Kutaisi Center") locationFee += 5;
                    if (formData.pickupLocation === "Kutaisi Airport") locationFee += 15;
                    if (formData.pickupLocation === "Tbilisi Center") locationFee += 45;
                    if (formData.pickupLocation === "Tbilisi Airport") locationFee += 55;
                    if (formData.pickupLocation === "Batumi Center") locationFee += 45;
                    if (formData.pickupLocation === "Batumi Airport") locationFee += 55;
                    if (formData.pickupLocation === "Other") locationFee += 70;
                    if (formData.dropOffLocation === "Kutaisi Office") locationFee += 0;
                    if (formData.dropOffLocation === "Kutaisi Center") locationFee += 5;
                    if (formData.dropOffLocation === "Kutaisi Airport") locationFee += 15;
                    if (formData.dropOffLocation === "Tbilisi Center") locationFee += 45;
                    if (formData.dropOffLocation === "Tbilisi Airport") locationFee += 55;
                    if (formData.dropOffLocation === "Batumi Center") locationFee += 45;
                    if (formData.dropOffLocation === "Batumi Airport") locationFee += 55;
                    if (formData.dropOffLocation === "Other") locationFee += 70;
                    setLocationPrice(locationFee);
                    setDays(daysCount);
                    if (daysCount > 0 && daysCount < 4) {
                        const price = (hours * (parseInt(data.priceFour) / 24)) + locationFee;
                        const income = (hours * (parseInt(formData.income) / 24)) + locationFee;
                        setCarPrice(parseInt(price - locationFee));
                        setFormData((prev) => ({ ...prev, priceSum: parseInt(price), income: income }))
                    } else if (daysCount > 3 && daysCount < 7) {
                        const price = (hours * (parseInt(data.priceThree) / 24)) + locationFee;
                        const income = (hours * (parseInt(formData.income) / 24)) + locationFee;
                        setCarPrice(parseInt(price - locationFee));
                        setFormData((prev) => ({ ...prev, priceSum: parseInt(price), income: income }))
                    } else if (daysCount > 6 && daysCount < 14) {
                        const price = (hours * (parseInt(data.priceTwo) / 24)) + locationFee;
                        const income = (hours * (parseInt(formData.income) / 24)) + locationFee;
                        setCarPrice(parseInt(price - locationFee));
                        setFormData((prev) => ({ ...prev, priceSum: parseInt(price), income: income }))
                    } else if (daysCount > 13) {
                        const price = (hours * (parseInt(data.priceOne) / 24)) + locationFee;
                        const income = (hours * (parseInt(formData.income) / 24)) + locationFee;
                        setCarPrice(parseInt(price - locationFee));
                        setFormData((prev) => ({ ...prev, priceSum: parseInt(price), income: income }))
                    }
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const submitOrder = () => {
        if (agreed) {
            axios.post(`/api/cars/${id}/booking`, { ...formData, income: data.isSubAgent == 0 ? formData.priceSum : formData.income, days: Math.round(days), carPrice: carPrice, locationPrice: locationPrice })
                .then((res) => {
                    const data = res.data;
                    setOrderId(data.id);
                    setNotification({ show: true, title: data.status, message: data.message });
                    setTimeout(() => {
                        setFormData({
                            pickupLocation: "", pickupDate: "", dropOffDate: "", dropOffLocation: "", gps: false, babySet: false, topBag: false, mp3Connector: false, skyCarRack: false,
                            wifiPack: false, tents2x: false, tents3x: false, sleepingBag: false, sleepingBag3x: false, paralon: false, bikeHalmet: false, mopedHalmet: false, rainCoat: false, msrSuperFly: false, fishingRod: false,
                            driverInCity: false, driverOutOfCity: false, name: "", surname: "", email: "", age: "", note: "", phone: "", paymentMethod: "", priceSum: 0, orderType: "website", income: ""
                        });
                        setOrderId(0)
                    }, 1300)
                    axios.post('/api/invoice', { ...formData, income: data.isSubAgent == 0 ? formData.priceSum : formData.income, orderId: data.id, days: Math.round(days), carPrice: carPrice, locationPrice: locationPrice }, {
                        responseType: 'blob', // Specify the response type as 'blob'
                    })
                        .then((response) => {
                            // Create a temporary URL for the blob
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const a = document.createElement('a');
                            a.href = url;
                            a.download = 'output.pdf'; // Specify the desired filename
                            a.click();
                            window.URL.revokeObjectURL(url);
                        })
                        .catch((error) => {
                            console.error('Error downloading PDF:', error);
                        });
                    setSteps(1);
                })
                .catch((err) => {
                    const data = err.response.data;
                    setNotification({ show: true, title: data.description, message: data.message })
                })
        } else {
            setNotification({ show: true, title: "Terms and conditions", message: "Please agree our terms and conditions" })
        }
    }

    return (
        <div className="container" style={{ minHeight: 'calc(100vh - 240px)' }}>
            <div className="row" style={{ marginBottom: "50px" }}>
                <div className="col-md-6">
                    <div className="car-description-container">
                        <div className="car-images-slider" style={{ backgroundImage: data.images && `url('${data.images.length > 0 ? data.images[index] : data.profileImage}')` }}>
                            <button className="slider-action-buttons" onClick={() => setIndex((prev) => {
                                if (prev === 0) {
                                    return data.images.length - 1
                                } else {
                                    return prev - 1
                                }
                            })}><FcPrevious className="actions-buttons" /></button>
                            <button className="slider-action-buttons" onClick={() => setIndex((prev) => {
                                if (data.images.length - 1 === index) {
                                    return 0
                                } else {
                                    return prev + 1
                                }
                            })}><FcNext className="actions-buttons" /></button>
                        </div>
                    </div>
                </div>
                <div className="col-md-6" style={{ paddingTop: "35px" }}>
                    <div className="car-description-cotnainer">
                        <div className="cars-title-container">
                            <h4>{data.mark} {data.model}</h4>
                            <h6>Minimum daily cost: {data.startingPrice}€</h6>
                        </div>
                        <h5>Pickup Locations: </h5>
                        <ul className="locations-on-cars-page">
                            {
                                typeof data.pickupLocations !== "undefined" && data.pickupLocations.map((l, k) => {
                                    return <li key={k * 9}>{l}</li>
                                })
                            }
                        </ul>
                        <div className="car-detail">
                            <h5 style={{ textAlign: "left", marginTop: "40px" }}> Description</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <ul className="car-details-list">
                                <li><strong>Transmission:</strong> <p>{data.transmission}</p></li>
                                <li><strong>Drive:</strong> <p>{data.drivingWheels}</p></li>
                                {(data.category !== "Moped" && data.category !== "Bike") && <li><strong>Doors:</strong> <p>{data.doors}</p></li>}
                                {(data.category !== "Moped" && data.category !== "Bike") &&<li><strong>Passengers:</strong> <p>{data.passengers}</p></li>}
                            </ul>
                        </div>
                        <div className="col-md-6">
                            {(data.category !== "Moped" && data.category !== "Bike") &&<ul className="car-details-list">
                                <li><strong>Engine:</strong> <p>{data.engine}</p></li>
                                <li><strong>Fuel:</strong> <p>{data.fuel}</p></li>
                                <li><strong>Baggage:</strong> <p>{data.baggage}</p></li>
                                <li><strong>Steering:</strong> <p>{data.steering}</p></li>
                            </ul>}
                        </div>
                    </div>
                </div>
                <div className="col-md-12" style={{ marginBottom: "25px" }}>
                    <div className="booking-details">
                        <div className="row">
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}><h5>Booking Details - € </h5><h5>{formData.priceSum}</h5></div>
                            <div className="hr"></div>
                            {steps > 1 && <>
                                <div className="col-md-3"><p className="c-page-details"><strong>From: </strong> {formData.pickupDate.replace("T", " ")}</p></div>
                                <div className="col-md-3"><p className="c-page-details"><strong>To: </strong> {formData.dropOffDate.replace("T", " ")}</p></div>
                                <div className="col-md-3"><p className="c-page-details"><strong>Pickup:</strong> {formData.pickupLocation}</p></div>
                                <div className="col-md-3"><p className="c-page-details"><strong>Dropoff:</strong> {formData.dropOffLocation}</p></div>
                            </>}
                            {steps > 1 && <div className="col-md-12" style={{ marginBottom: "25px" }}>
                                <Table responsive >
                                    <thead>
                                        <tr>
                                            <th>Service name</th>
                                            <th>Days</th>
                                            <th>Net price</th>
                                            <th>Tax</th>
                                            <th>Total price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{formData.mark} {formData.model} Rental Cost</td>
                                            <td>{Math.round(days)}</td>
                                            <td>{carPrice} €</td>
                                            <td>0 €</td>
                                            <td>{carPrice} €</td>
                                        </tr>
                                        <tr>
                                            <td>Pickup/Drop Off Fee</td>
                                            <td>{Math.round(days)}</td>
                                            <td>{locationPrice} €</td>
                                            <td>0 €</td>
                                            <td>{locationPrice} €</td>
                                        </tr>
                                        {formData.gps && <tr>
                                            <td>GPS System</td>
                                            <td>{Math.round(days)}</td>
                                            <td>{5 * days} €</td>
                                            <td>0 €</td>
                                            <td>{5 * days} €</td>
                                        </tr>}
                                        {formData.babySet && <tr>
                                            <td>Baby Set</td>
                                            <td>{Math.round(days)}</td>
                                            <td>{5 * days} €</td>
                                            <td>0 €</td>
                                            <td>{5 * days} €</td>
                                        </tr>}
                                        {formData.topBag && <tr>
                                            <td>Top Bag</td>
                                            <td>{Math.round(days)}</td>
                                            <td>{10 * days} €</td>
                                            <td>0 €</td>
                                            <td>{10 * days} €</td>
                                        </tr>}
                                        {formData.mp3Connector && <tr>
                                            <td>MP3 Connector</td>
                                            <td>{Math.round(days)}</td>
                                            <td>{0 * days} €</td>
                                            <td>0 €</td>
                                            <td>{0 * days} €</td>
                                        </tr>}
                                        {formData.skyCarRack && <tr>
                                            <td>Sku Car Rack</td>
                                            <td>{Math.round(days)}</td>
                                            <td>{8 * days} €</td>
                                            <td>0 €</td>
                                            <td>{8 * days} €</td>
                                        </tr>}
                                        {formData.wifiPack && <tr>
                                            <td>WIFI Pack</td>
                                            <td>{Math.round(days)}</td>
                                            <td>{15 * days} €</td>
                                            <td>0 €</td>
                                            <td>{15 * days} €</td>
                                        </tr>}
                                        {formData.msrSuperFly && <tr>
                                            <td>MSR Super Fly</td>
                                            <td>{Math.round(days)}</td>
                                            <td>{4 * days} €</td>
                                            <td>0 €</td>
                                            <td>{4 * days} €</td>
                                        </tr>}
                                        {formData.fishingRod && <tr>
                                            <td>Fishing Rod</td>
                                            <td>{Math.round(days)}</td>
                                            <td>{3 * days} €</td>
                                            <td>0 €</td>
                                            <td>{3 * days} €</td>
                                        </tr>}
                                        {formData.driverInCity && <tr>
                                            <td>Driver In City</td>
                                            <td>{Math.round(days)}</td>
                                            <td>{25 * days} €</td>
                                            <td>0 €</td>
                                            <td>{25 * days} €</td>
                                        </tr>}
                                        {formData.bikeHalmet && <tr>
                                            <td>Bike Halmet</td>
                                            <td>{Math.round(days)}</td>
                                            <td>{10 * days} €</td>
                                            <td>0 €</td>
                                            <td>{10 * days} €</td>
                                        </tr>}
                                        {formData.driverOutOfCity > 0 && <tr>
                                            <td>Driver Out Of City</td>
                                            <td>{Math.round(days)}</td>
                                            <td>{35 * days} €</td>
                                            <td>0 €</td>
                                            <td>{35 * days} €</td>
                                        </tr>}
                                        {formData.rainCoat > 0 && <tr>
                                            <td>Rain Coat</td>
                                            <td>{Math.round(days)}</td>
                                            <td>{formData.rainCoat * 1 * days} €</td>
                                            <td>0 €</td>
                                            <td>{formData.rainCoat * 1 * days} €</td>
                                        </tr>}
                                        {formData.mopedHalmet > 0 && <tr>
                                            <td>Moped Halmet {`(${formData.mopedHalmet}x)`}</td>
                                            <td>{Math.round(days)}</td>
                                            <td>{formData.mopedHalmet * 5 * days} €</td>
                                            <td>0 €</td>
                                            <td>{formData.mopedHalmet * 5 * days} €</td>
                                        </tr>}
                                        {formData.tents2x > 0 && <tr>
                                            <td>Tents 2x {`(${formData.tents2x}x)`}</td>
                                            <td>{Math.round(days)}</td>
                                            <td>{formData.tents2x * 5 * days} €</td>
                                            <td>0 €</td>
                                            <td>{formData.tents2x * 5 * days} €</td>
                                        </tr>}
                                        {formData.tents3x > 0 && <tr>
                                            <td>Tents 2x {`(${formData.tents3x}x)`}</td>
                                            <td>{Math.round(days)}</td>
                                            <td>{formData.tents3x * 7 * days} €</td>
                                            <td>0 €</td>
                                            <td>{formData.tents3x * 7 * days} €</td>
                                        </tr>}
                                        {formData.sleepingBag > 0 && <tr>
                                            <td>SLEEPING BAG 3X {`(${formData.sleepingBag}x)`}</td>
                                            <td>{Math.round(days)}</td>
                                            <td>{formData.sleepingBag * 3 * days} €</td>
                                            <td>0 €</td>
                                            <td>{formData.sleepingBag * 3 * days} €</td>
                                        </tr>}
                                        {formData.sleepingBag3x > 0 && <tr>
                                            <td>SLEEPING BAG 3X {`(${formData.sleepingBag3x}x)`}</td>
                                            <td>{Math.round(days)}</td>
                                            <td>{formData.sleepingBag3x * 4 * days} €</td>
                                            <td>0 €</td>
                                            <td>{formData.sleepingBag3x * 4 * days} €</td>
                                        </tr>}
                                        {formData.paralon > 0 && <tr>
                                            <td>Paralon {`(${formData.paralon}x)`}</td>
                                            <td>{Math.round(days)}</td>
                                            <td>{formData.paralon * 2 * days} €</td>
                                            <td>0 €</td>
                                            <td>{formData.paralon * 2 * days} €</td>
                                        </tr>}
                                        <tr>
                                            <td style={{ background: "gray", color: "white" }}>ToTal</td>
                                            <td style={{ background: "gray", color: "white" }}>{Math.round(days)}</td>
                                            <td style={{ background: "gray", color: "white" }}>{formData.priceSum}</td>
                                            <td style={{ background: "gray", color: "white" }}>0 €</td>
                                            <td style={{ background: "gray", color: "white" }}>{formData.priceSum}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>}
                            {steps === 2 && <Characteristics category={data.category} amount={amount} setSteps={setSteps} days={days} formData={formData} setFormData={setFormData} />}
                            {steps === 1 && <>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Pickup Location</label>
                                        <select className="form-control" value={formData.pickupLocation} onChange={(e) => setFormData((prev) => ({ ...prev, pickupLocation: e.target.value }))}>
                                            <option>Select location</option>
                                            <option value={"Kutaisi Center"}>Kutaisi Center</option>
                                            <option value={"Kutaisi Airport"}>Kutaisi Airport</option>
                                            <option value={"Tbilisi Center"}>Tbilisi Center</option>
                                            <option value={"Tbilisi Airport"}>Tbilisi Airport</option>
                                            <option value={"Batumi Center"}>Batumi Center</option>
                                            <option value={"Batumi Airport"}>Batumi Airport</option>
                                            <option value={"Other"}>At All Other Places</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Pickup Date</label>
                                        <input className="form-control" min={today} type="datetime-local" value={formData.pickupDate} onChange={(e) => {
                                            setFormData((prev) => ({ ...prev, pickupDate: e.target.value }));
                                            const newDate = new Date(e.target.value);
                                            const min = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate() + 1 < 10 ? `0${newDate.getDate() + 1}` : newDate.getDate() + 1}T00:00`;
                                            setMinDate(min);
                                        }} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Dropoff Date</label>
                                        <input className="form-control" min={minDate} type="datetime-local" value={formData.dropOffDate} onChange={(e) => setFormData((prev) => ({ ...prev, dropOffDate: e.target.value }))} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Dropoff Location</label>
                                        <select className="form-control" value={formData.dropOffLocation} onChange={(e) => setFormData((prev) => ({ ...prev, dropOffLocation: e.target.value }))}>
                                            <option>Select location</option>
                                            <option value={"Kutaisi Center"}>Kutaisi Center</option>
                                            <option value={"Kutaisi Airport"}>Kutaisi Airport</option>
                                            <option value={"Tbilisi Center"}>Tbilisi Center</option>
                                            <option value={"Tbilisi Airport"}>Tbilisi Airport</option>
                                            <option value={"Batumi Center"}>Batumi Center</option>
                                            <option value={"Batumi Airport"}>Batumi Airport</option>
                                            <option value={"Other"}>At All Other Places</option>
                                        </select>
                                    </div>
                                    <button className="start-booking-button" onClick={() => {
                                        if (formData.pickupDate !== "" && formData.dropOffDate !== "" && formData.pickupLocation !== "" && formData.dropOffLocation !== "") {
                                            const startDate = +new Date(formData.pickupDate);
                                            const endDate = +new Date(formData.dropOffDate);
                                            const daysCount = (endDate - startDate) / 86400000;
                                            const hours = (endDate - startDate) / 3600000;
                                            let locationFee = 0;
                                            if (formData.pickupLocation === "Kutaisi Office") locationFee += 0;
                                            if (formData.pickupLocation === "Kutaisi Center") locationFee += 5;
                                            if (formData.pickupLocation === "Kutaisi Airport") locationFee += 15;
                                            if (formData.pickupLocation === "Tbilisi Center") locationFee += 45;
                                            if (formData.pickupLocation === "Tbilisi Airport") locationFee += 55;
                                            if (formData.pickupLocation === "Batumi Center") locationFee += 45;
                                            if (formData.pickupLocation === "Batumi Airport") locationFee += 55;
                                            if (formData.pickupLocation === "Other") locationFee += 70;
                                            if (formData.dropOffLocation === "Kutaisi Office") locationFee += 0;
                                            if (formData.dropOffLocation === "Kutaisi Center") locationFee += 5;
                                            if (formData.dropOffLocation === "Kutaisi Airport") locationFee += 15;
                                            if (formData.dropOffLocation === "Tbilisi Center") locationFee += 45;
                                            if (formData.dropOffLocation === "Tbilisi Airport") locationFee += 55;
                                            if (formData.dropOffLocation === "Batumi Center") locationFee += 45;
                                            if (formData.dropOffLocation === "Batumi Airport") locationFee += 55;
                                            if (formData.dropOffLocation === "Other") locationFee += 70;
                                            setLocationPrice(locationFee);
                                            setDays(daysCount);
                                            if (daysCount > 0 && daysCount < 4) {
                                                const price = (hours * (parseInt(data.priceFour) / 24)) + locationFee;
                                                const income = (hours * (parseInt(formData.income) / 24)) + locationFee;
                                                setAmount(parseInt(price));
                                                setCarPrice(parseInt(price - locationFee));
                                                setFormData((prev) => ({ ...prev, priceSum: parseInt(price), income: income }))
                                            } else if (daysCount > 3 && daysCount < 7) {
                                                const price = (hours * (parseInt(data.priceThree) / 24)) + locationFee;
                                                const income = (hours * (parseInt(formData.income) / 24)) + locationFee;
                                                setAmount(parseInt(price));
                                                setCarPrice(parseInt(price - locationFee));
                                                setFormData((prev) => ({ ...prev, priceSum: parseInt(price), income: income }))
                                            } else if (daysCount > 6 && daysCount < 14) {
                                                const price = (hours * (parseInt(data.priceTwo) / 24)) + locationFee;
                                                const income = (hours * (parseInt(formData.income) / 24)) + locationFee;
                                                setAmount(parseInt(price));
                                                setCarPrice(parseInt(price - locationFee));
                                                setFormData((prev) => ({ ...prev, priceSum: parseInt(price), income: income }))
                                            } else if (daysCount > 13) {
                                                const price = (hours * (parseInt(data.priceOne) / 24)) + locationFee;
                                                const income = (hours * (parseInt(formData.income) / 24)) + locationFee;
                                                setAmount(parseInt(price));
                                                setCarPrice(parseInt(price - locationFee));
                                                setFormData((prev) => ({ ...prev, priceSum: parseInt(price), income: income }))
                                            }
                                            setSteps(2);
                                        } else {
                                            setNotification({ show: true, title: "Failed", message: "All fields are required" })
                                        }
                                    }}>Start booking</button>
                                </div>
                            </>}
                            {
                                steps === 3 && (
                                    <>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>First Name</label>
                                                <input className="form-control" type="text" value={formData.name} onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Last Name</label>
                                                <input className="form-control" type="text" value={formData.surname} onChange={(e) => setFormData((prev) => ({ ...prev, surname: e.target.value }))} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input className="form-control" type="email" value={formData.email} onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Age</label>
                                                <input className="form-control" type="text" value={formData.age} onChange={(e) => setFormData((prev) => ({ ...prev, age: e.target.value }))} />
                                            </div>
                                            <div className="form-group">
                                                <label>Phone</label>
                                                <input className="form-control" type="text" value={formData.phone} onChange={(e) => setFormData((prev) => ({ ...prev, phone: e.target.value }))} />
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <label>Note</label>
                                                <textarea className="form-control" style={{ height: "121px" }} value={formData.note} onChange={(e) => setFormData((prev) => ({ ...prev, note: e.target.value }))} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <select className="form-control" value={formData.paymentMethod} onChange={(e) => setFormData((prev) => ({ ...prev, paymentMethod: e.target.value }))} >
                                                    <option>Select payment method</option>
                                                    <option value={"cash"}>With Cash</option>
                                                    <option value={"card"}>With Card +3%</option>
                                                    <option value={"paypal"}>With Paypal</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4">
                                            <div className="terms-and-cond">
                                                <label onClick={() => setShowTerms(!showTerms)} className="target-link">Terms And Conditions</label>
                                                <input id="agree" type="checkbox" value={agreed} onChange={(e) => setAgreed(e.target.checked)} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <Button variant="dark" style={{ width: "100%", marginTop: "20px" }} onClick={() => setSteps(2)}>Back</Button>
                                        </div>
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4">
                                            <button style={{ marginTop: "20px" }} className="start-booking-button" onClick={() => {
                                                if (formData.name === "" || formData.surname === "" || formData.email === "" || formData.age === "" || formData.phone === "" || formData.paymentMethod === "") {
                                                    setNotification({ show: true, title: "Failed", message: "All fields are required" })
                                                } else {
                                                    setShowDetails(true)
                                                }
                                            }}>Check order details</button>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-6" style={{ marginTop: "40px" }}>
                    <div className="price-include-box">
                        <strong style={{ marginBottom: "5px", color: "white" }}>Prices includes</strong>
                        <p style={{ marginBottom: "8px", color: "white" }}>{data.priceIncludes}</p>
                    </div>
                </div>
                <div className="pricing-container col-md-6" style={{ marginTop: "40px" }}>
                    <ul className="pricing-list">
                        <li>
                            <strong className="days-count">1-3 DAY</strong>
                            <p className="price-on-days-count">{data.priceFour}€</p>
                        </li>
                        <li>
                            <strong className="days-count">4-6 DAY</strong>
                            <p className="price-on-days-count">{data.priceThree}€</p>
                        </li>
                        <li>
                            <strong className="days-count">7-13 DAY</strong>
                            <p className="price-on-days-count">{data.priceTwo}€</p>
                        </li>
                        <li>
                            <strong className="days-count">14 OR MORE</strong>
                            <p className="price-on-days-count">{data.priceOne}€</p>
                        </li>
                    </ul>
                </div>
                <div className="col-md-12">
                    <div className="insurance-conditions-box">
                        <ul>
                            <li>THE DRIVER MUST BE AT LEAST 21 YEARS OLD AND HOLD RELEVANT DRIVING LICENSE.</li>
                            <li>WITH AUTO INSURANCE YOU ARE INSURED AGAINST THE FOLLOWING RISKS:</li>
                            <li>ROAD ACCIDENT; LARCENY, THEFT OR ROBBERY; FIRE, EXPLOSION; VANDALISM; FALLING OBJECTS; NATURAL DISASTERS (INCLUDING,
                                EARTHQUAKE, HAIL); ACCESSORIES; THIRD PART LIABILITY INSURANCE (MTPL).</li>
                            <li>INSTRUCTIONS FOR ACTION IN CASE OF AN INSURANCE ACCIDENT:</li>
                            <li>DO NOT MOVE THE VEHICLE FROM THE INCIDENT LOCATION; UPON OCCURRENCE OF INCIDENT CALL THE PATROL POLICE TO REGISTER THE
                                INCIDENT - 112! CALL TO THE HOTLINE OF OUR CALL-CENTER: +995 32 2 505 111 SERVED BY AN INSURANCE COMPANY GPI VIENNA INSURANCE
                                GROUP.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Notification title={notification.title} message={notification.message} show={notification.show} setShow={setNotification} />
            <Modal show={showTerms} onHide={() => setShowTerms(false)} size="lg" centered>
                <Modal.Body>
                    <iframe src="https://carrentingeorgia.ge/termsandrules.html" frameborder="0" style={{ height: "90vh" }} width={"100%"}></iframe>
                </Modal.Body>
            </Modal>
            {showDetails && <OrderDetailsModal orderId={orderId} submit={submitOrder} price={carPrice} days={Math.round(days)} location={locationPrice} data={formData} show={showDetails} image={data.profileImage} setShow={setShowDetails} />}
        </div>
    )
}

export default CarPage