import { useEffect, useState } from "react";
import './addCarsModal.css'

const { Modal } = require("react-bootstrap")

const AddCarsModal = ({ show, hide, formData, setFormData, action, onSubmit }) => {
    const [pickupLocations, setPickupLocations] = useState("");
    const [dropOffLocations, setDropOffLocations] = useState("");
    useEffect(() => {
        const date = new Date(formData.insuranceDate);
        setFormData((prev) => ({...prev, insuranceDate: `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`}))
    }, [show])
    return (
        show && <Modal show={show} onHide={() => hide(false)} size={"xl"} centered>
            <Modal.Header><h4>{action === "add" ? "Add new car" : "Edit car"}</h4></Modal.Header>
            <Modal.Body>
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Mark</label>
                                <input
                                    placeholder="Enter Mark"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, mark: e.target.value }))}
                                    value={formData.mark}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Model</label>
                                <input
                                    placeholder="Enter Model"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, model: e.target.value }))}
                                    value={formData.model}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Year</label>
                                <input
                                    placeholder="Enter Year"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, year: e.target.value }))}
                                    value={formData.year}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Category</label>
                                <select
                                    onChange={(e) => setFormData((prev) => ({ ...prev, category: e.target.value }))}
                                    value={formData.category}
                                    className="form-control"
                                >
                                    <option>Select Category</option>
                                    <option value={"Car"}>Car</option>
                                    <option value={"Moped"}>Moped</option>
                                    <option value="Bike">Bike</option>
                                    <option value="Minivan">Minivan</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Minimum Price</label>
                                <input
                                    placeholder="Enter Minimum price"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, startingPrice: e.target.value }))}
                                    value={formData.startingPrice}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Price I</label>
                                <input
                                    placeholder="Enter Price I"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, priceOne: e.target.value }))}
                                    value={formData.priceOne}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Price II</label>
                                <input
                                    placeholder="Enter Price II"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, priceTwo: e.target.value }))}
                                    value={formData.priceTwo}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Price III</label>
                                <input
                                    placeholder="Enter Price III"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, priceThree: e.target.value }))}
                                    value={formData.priceThree}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Price IV</label>
                                <input
                                    placeholder="Enter Price IV"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, priceFour: e.target.value }))}
                                    value={formData.priceFour}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Insurance End Date</label>
                                <input
                                    type="date"
                                    onChange={(e) => {
                                        const date = new Date(e.target.value);
                                        const d = `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;
                                        setFormData((prev) => ({ ...prev, insuranceDate: d }));
                                    }}
                                    value={formData.insuranceDate}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Insurance Price</label>
                                <input
                                    placeholder="Enter Insurance Price"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, insurancePrice: e.target.value }))}
                                    value={formData.insurancePrice}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Transmission</label>
                                <select
                                    onChange={(e) => setFormData((prev) => ({ ...prev, transmission: e.target.value }))}
                                    value={formData.transmission}
                                    className="form-control"
                                >
                                    <option>Select Transmission</option>
                                    <option value={"Automatic"}>Automatic</option>
                                    <option value={"Manual"}>Manual</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Driving Wheels</label>
                                <select
                                    onChange={(e) => setFormData((prev) => ({ ...prev, drivingWheels: e.target.value }))}
                                    value={formData.drivingWheels}
                                    className="form-control"
                                >
                                    <option>Select Driving Wheels</option>
                                    <option value={"AWD"}>AWD</option>
                                    <option value={"4WD"}>4WD</option>
                                    <option value={"2WD"}>2WD</option>
                                    <option value={"1WD"}>1WD</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Doors</label>
                                <select
                                    onChange={(e) => setFormData((prev) => ({ ...prev, doors: e.target.value }))}
                                    value={formData.doors}
                                    className="form-control"
                                >
                                    <option>Select Doors</option>
                                    <option value={"4/5"}>4/5</option>
                                    <option value={"2/3"}>2/3</option>
                                    <option value={"0/1"}>0/1</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Passengers</label>
                                <input
                                    placeholder="Enter Passengers Quantity"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, passengers: e.target.value }))}
                                    value={formData.passengers}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Engine</label>
                                <input
                                    placeholder="Enter Engine"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, engine: e.target.value }))}
                                    value={formData.engine}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Fuel</label>
                                <input
                                    placeholder="Enter Fuel"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, fuel: e.target.value }))}
                                    value={formData.fuel}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Baggage</label>
                                <select
                                    onChange={(e) => setFormData((prev) => ({ ...prev, baggage: e.target.value }))}
                                    value={formData.baggage}
                                    className="form-control"
                                >
                                    <option>Select Baggage Size</option>
                                    <option value={"Small"}>Small</option>
                                    <option value={"Normal"}>Normal</option>
                                    <option value={"Big"}>Big</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Price include</label>
                                <textarea
                                    style={{ height: "122px" }}
                                    placeholder="Enter Price include text"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, priceIncludes: e.target.value }))}
                                    value={formData.priceIncludes}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Steeling</label>
                                <select
                                    onChange={(e) => setFormData((prev) => ({ ...prev, steering: e.target.value }))}
                                    value={formData.steering}
                                    className="form-control"
                                >
                                    <option>Select Steeling</option>
                                    <option value={"Left"}>Left</option>
                                    <option value={"Right"}>Right</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Car State number</label>
                                <input
                                    placeholder="Enter Car State number"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, carNumber: e.target.value }))}
                                    value={formData.carNumber}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group" style={{ height: "61px" }}>
                                <label>Subagent</label>
                                <input
                                    type="checkbox"
                                    onChange={(e) => setFormData((prev) => ({ ...prev, isSubAgent: e.target.checked }))}
                                    value={formData.isSubAgent}
                                    defaultChecked={[formData].some(i => i.isSubAgent === true)}
                                    style={{ width: "20px", height: "25px" }}
                                />
                            </div>
                            {
                                formData.isSubAgent && (
                                    <>
                                        <div className="form-group">
                                            <label>Holder Name</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Subagent Name"
                                                onChange={(e) => setFormData((prev) => ({ ...prev, holderName: e.target.value }))}
                                                value={formData.holderName}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Holder Surname</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Subagent Surname"
                                                onChange={(e) => setFormData((prev) => ({ ...prev, holderSurname: e.target.value }))}
                                                value={formData.holderSurname}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Holder Phone</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Subagent Phone"
                                                onChange={(e) => setFormData((prev) => ({ ...prev, holderPhone: e.target.value }))}
                                                value={formData.holderPhone}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Holder Email</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Subagent Email"
                                                onChange={(e) => setFormData((prev) => ({ ...prev, holderEmail: e.target.value }))}
                                                value={formData.holderEmail}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Income</label>
                                            <input
                                                type="text"
                                                required
                                                placeholder="Enter income"
                                                onChange={(e) => setFormData((prev) => ({ ...prev, income: e.target.value }))}
                                                value={formData.income}
                                                className="form-control"
                                            />
                                        </div>
                                    </>
                                )
                            }
                        </div>
                        <div className="col-md-12" style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px"}}>
                            <button className="orange-button" style={{width: "50%"}}>{action === "add" ? "Add New" : "Save"}</button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default AddCarsModal