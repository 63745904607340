import axios from 'axios';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';

const OrderImagesModal = ({ role, show, setShow, data, images, setCars, setImages }) => {
    const [rentalImages, setRentalImage] = useState(null);
    const [receiptImages, setReceiptImage] = useState(null);
    const [selectedRentalImage, setSelectedRentalImage] = useState([]);
    const [selectedReceiptImage, setSelectedReceiptImage] = useState([]);
    const [loading, setLoading] = useState({ profile: false, multiple: false });
    const [locations, setLocations] = useState({ pickup: "", dropoff: "" });
    const [amount, setAmount] = useState({ pickup: "", dropOff: "" });

    const handleImageChange = (e) => {
        setReceiptImage(e.target.files)
        const files = e.target.files;
        const imageArray = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();

            reader.onload = (event) => {
                imageArray.push(event.target.result);

                // If all images have been processed, update the state
                if (imageArray.length === files.length) {
                    setSelectedReceiptImage(imageArray);
                }
            };

            reader.readAsDataURL(file);
        }
    };

    console.log(images);

    return (
        <Modal show={show} onHide={() => setShow(false)} centered size='lg'>
            <Modal.Header><h4>Order Images</h4></Modal.Header>
            <Modal.Body>
                <p className="modal-section-title">Pick Up Images</p>
                <select className='form-control' onChange={(e) => {
                    setLocations((prev) => ({ ...prev, pickup: e.target.value }));
                    const v = e.target.value;
                    if (v === "Kutaisi Office") setAmount((prev) => ({ ...prev, pickup: 0 }))
                    if (v === "Kutaisi Center") setAmount((prev) => ({ ...prev, pickup: 15 }))
                    if (v === "Kutaisi Airport") setAmount((prev) => ({ ...prev, pickup: 20 }))
                    if (v === "Tbilisi Center") setAmount((prev) => ({ ...prev, pickup: 30 }))
                    if (v === "Tbilisi Airport") setAmount((prev) => ({ ...prev, pickup: 35 }))
                    if (v === "Batumi Center") setAmount((prev) => ({ ...prev, pickup: 25 }))
                    if (v === "Batumi Airport") setAmount((prev) => ({ ...prev, pickup: 30 }))
                    if (v === "Other") setAmount((prev) => ({ ...prev, pickup: 40 }))
                }} style={{ maxWidth: "270px", margin: "auto auto 20px  auto" }}>
                    <option >Select Location</option>
                    <option value={"Kutaisi Office"}>Kutaisi Office</option>
                    <option value={"Kutaisi Center"}>Kutaisi Center</option>
                    <option value={"Kutaisi Airport"}>Kutaisi Airport</option>
                    <option value={"Tbilisi Center"}>Tbilisi Center</option>
                    <option value={"Tbilisi Airport"}>Tbilisi Airport</option>
                    <option value={"Batumi Center"}>Batumi Center</option>
                    <option value={"Batumi Airport"}>Batumi Airport</option>
                    <option value={"Other"}>At All Other Places</option>
                </select>
                <div className="upload-images-box">
                    {selectedRentalImage.length > 0 && (
                        <div>
                            <h5>Selected Images:</h5>
                            <ul style={{ listStyleType: "none" }}>
                                {selectedRentalImage.map((image, index) => (
                                    <li key={index * 8}>
                                        <img className="profile-image-on-modal" src={image} alt={`Selected Image ${index + 1}`} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {(images.rental && !selectedRentalImage.length > 0) && (
                        <div>
                            <h5>Uploaded Images:</h5>
                            <ul style={{ listStyleType: "none" }}>
                                {images.rental.map((image, index) => (
                                    <li key={index}>
                                        <img className="profile-image-on-modal" src={image} alt={`Selected Image ${index + 1}`} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <input id="upload-profile-image" type="file" hidden onChange={(e) => {
                        setRentalImage(e.target.files)
                        const files = e.target.files;
                        const imageArray = [];

                        for (let i = 0; i < files.length; i++) {
                            const file = files[i];
                            const reader = new FileReader();

                            reader.onload = (event) => {
                                imageArray.push(event.target.result);

                                // If all images have been processed, update the state
                                if (imageArray.length === files.length) {
                                    setSelectedRentalImage(imageArray);
                                }
                            };

                            reader.readAsDataURL(file);
                        }
                    }} multiple />
                    <label htmlFor="upload-profile-image" className="upload-file-button">Select Image</label>
                    {(!images.rental || role === "admin") && <button style={{ marginTop: "10px", background: "#0a56f7" }} disabled={loading.profile} className="upload-file-button" onClick={() => {
                        setLoading((prev) => ({ ...prev, profile: true }));
                        const formData = new FormData();
                        for (let i = 0; i < rentalImages.length; i++) {
                            formData.append('images', rentalImages[i]);
                        }
                        const d = new Date();
                        const current = `${d.getFullYear()}-${d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1}-${d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()}`;
                        formData.append("date", current);
                        formData.append("id", data.id);
                        formData.append("location", locations.pickup);
                        formData.append("type", "rental");
                        formData.append("amount", amount.pickup);
                        axios.post(`/api/orders/images`, formData)
                            .then((res) => {
                                alert("success");
                                const url = res.data.data;
                                setSelectedRentalImage([]);
                                setLoading((prev) => ({ ...prev, profile: false }));
                                setCars((prev) => {
                                    const d = prev.map((c) => {
                                        if (c.id === data.id) {
                                            console.log(c.id)
                                            return { ...c, rentalImages: url }
                                        } else {
                                            return { ...c }
                                        }
                                    });
                                    return [...d];
                                });
                                setImages((prev) => ({ ...prev, rental: url }))
                            })
                            .catch((err) => {
                                alert("failed");
                                setLoading((prev) => ({ ...prev, profile: false }));
                            })
                    }}>Upload</button>}
                </div>
                <p className="modal-section-title">Drop off Images</p>
                <select className='form-control' onChange={(e) => {
                    setLocations((prev) => ({ ...prev, dropoff: e.target.value }))
                    const v = e.target.value;
                    if(v === "Kutaisi Office") setAmount((prev) => ({...prev, dropoff: 0}))
                    if(v === "Kutaisi Center") setAmount((prev) => ({...prev, dropoff: 15}))
                    if(v === "Kutaisi Airport") setAmount((prev) => ({...prev, dropoff: 20}))
                    if(v === "Tbilisi Center") setAmount((prev) => ({...prev, dropoff: 30}))
                    if(v === "Tbilisi Airport") setAmount((prev) => ({...prev, dropoff: 35}))
                    if(v === "Batumi Center") setAmount((prev) => ({...prev, dropoff: 25}))
                    if(v === "Batumi Airport") setAmount((prev) => ({...prev, dropoff: 30}))
                    if(v === "Other") setAmount((prev) => ({...prev, dropoff: 40}))
                }} style={{ maxWidth: "270px", margin: "auto auto 20px  auto" }}>
                    <option >Select Location</option>
                    <option value={"Kutaisi Center"}>Kutaisi Center</option>
                    <option value={"Kutaisi Airport"}>Kutaisi Airport</option>
                    <option value={"Tbilisi Center"}>Tbilisi Center</option>
                    <option value={"Tbilisi Airport"}>Tbilisi Airport</option>
                    <option value={"Batumi Center"}>Batumi Center</option>
                    <option value={"Batumi Airport"}>Batumi Airport</option>
                    <option value={"Other"}>At All Other Places</option>
                </select>
                <div className="upload-images-box">
                    {selectedReceiptImage.length > 0 && (
                        <div>
                            <h2>Selected Images:</h2>
                            <ul style={{ listStyleType: "none" }}>
                                {selectedReceiptImage.map((image, index) => (
                                    <li key={index}>
                                        <img className="profile-image-on-modal" src={image} alt={`Selected Image ${index + 1}`} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {(images.receipt && !selectedReceiptImage.length > 0) && (
                        <div>
                            <h5>Uploaded Images:</h5>
                            <ul style={{ listStyleType: "none" }}>
                                {images.receipt.map((image, index) => (
                                    <li key={index}>
                                        <img className="profile-image-on-modal" src={image} alt={`Selected Image ${index + 1}`} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <input id="upload-multiple-image" multiple type="file" hidden onChange={handleImageChange} />
                    <label htmlFor="upload-multiple-image" className="upload-file-button">Select Image</label>
                    {(!images.receipt || role === "admin") && <button style={{ marginTop: "10px", background: "#0a56f7" }} disabled={loading.multiple} className="upload-file-button" onClick={() => {
                        setLoading((prev) => ({ ...prev, multiple: true }));
                        const formData = new FormData();
                        const d = new Date();
                        const current = `${d.getFullYear()}-${d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1}-${d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()}`;
                        for (let i = 0; i < receiptImages.length; i++) {
                            formData.append('images', receiptImages[i]);
                        }
                        formData.append("location", locations.dropoff);
                        formData.append("id", data.id);
                        formData.append("date", current);
                        formData.append("amount", amount.dropoff);
                        formData.append("type", "receipt");
                        axios.post(`/api/orders/images`, formData)
                            .then((res) => {
                                alert("success");
                                const url = res.data.data;
                                setLoading((prev) => ({ ...prev, multiple: false }));
                                setCars((prev) => {
                                    const d = prev.map((c) => {
                                        if (c.id === data.id) {
                                            return { ...c, receiveImages: [...url] }
                                        } else {
                                            return { ...c }
                                        }
                                    });
                                    return [...d];
                                });
                                setSelectedReceiptImage([]);
                                setImages((prev) => ({ ...prev, receipt: [...url] }))
                            })
                            .catch((err) => {
                                alert("failed");
                                setLoading((prev) => ({ ...prev, multiple: false }))
                            })
                    }}>Upload</button>}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default OrderImagesModal