import { useEffect, useState } from "react";
import DataTable from "../../../Common/DataTable/DataTable";
import axios from 'axios';
import Notification from "../../../Hooks/Notification"
import AddCarsModal from "../Modals/AddCarsModal";
import './AdminCars.css'
import { Button, Modal } from "react-bootstrap";
import UploadImages from "./UploadImages";

function dateRangesOverlap(dateRange1, dateRange2) {
    // Convert date strings to Date objects
    const start1 = new Date(dateRange1.startDate);
    const end1 = new Date(dateRange1.endDate);
    const start2 = new Date(dateRange2.startDate);
    const end2 = new Date(dateRange2.endDate);

    const obj = {isBusy: !(end1 < start2 || end2 < start1), date: dateRange1};
    // Check for overlap
    return obj
}

const AdminCars = () => {
    const [showInsuranceModal, setShowInsuranceModal] = useState(false);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [filterDates, setFilterDates] = useState({startDate: "", endDate: ""});
    const [keyword, setKeyword] = useState("");
    const [cars, setCars] = useState([]);
    const [showBookingsModal, setShowBookingsModal] = useState(false);
    const [notification, setNotification] = useState({ show: false, title: "", message: "" });
    const [showAddCarsModal, setShowAddCarsModal] = useState(false);
    const [action, setAction] = useState("add");
    const [images, setImages] = useState({ profile: "", multiple: [] });
    const [formData, setFormData] = useState({
        mark: "", model: "", year: "", images: [], profileImage: "", category: "", description: "", pickupLocations: [], dropOffLocations: [], characteristics: [],
        options: [], totalUnits: "", startingPrice: "", currency: "EUR", carClass: "", priceOne: "", priceTwo: "", priceThree: "", priceFour: "", transmission: "",
        drivingWheels: "", doors: "", passengers: "", engine: "", fuel: "", baggage: "", steering: "", priceIncludes: "", insuranceConditions: [], orderDates: [],
        isSubAgent: false, holderName: "", holderSurname: "", holderPhone: "", holderEmail: "", insuranceDate: "", insurancePrice: "", carNumber: ""
    });
    const [showDeleteCarsModal, setShowDeleteCarsModal] = useState(false);
    const [active, setActive] = useState({});
    const [showUploadImagesModal, setShowUploadImagesModal] = useState(false);
    const [insuranceReminder, setInsuranceReminder] = useState([]);

    const headers = ["Id", "Mark", "Model", "Year", "Minimum price", "Is subagent", "Holder", ""];
    const showParams = [
        { type: "string", name: "id" }, { type: "string", name: "mark" }, { type: "string", name: "model" }, { type: "string", name: "year" },
        { type: "string", name: "startingPrice" }, { type: "boolean", name: "isSubAgent" }, { type: "string", name: "holderName" }
    ];
    const [busy, setBusy] = useState({});

    const actions = [
        {
            name: "Check insurance",
            action: () => {
                axios(`/api/cars/insurance/limit`)
                    .then((res) => {
                        setInsuranceReminder(res.data.data);
                        if(res.data.data.length > 0){
                            setShowInsuranceModal(true)
                        }else{
                            setNotification({show: true, title: "Insurance", message: "No insurance alerts"})
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        },
        {
            name: "Add new",
            action: () => {
                setFormData({
                    mark: "", model: "", year: "", images: [], profileImage: "", category: "", description: "", pickupLocations: [], dropOffLocations: [], characteristics: [],
                    options: [], totalUnits: "", startingPrice: "", currency: "EUR", carClass: "", priceOne: "", priceTwo: "", priceThree: "", priceFour: "", transmission: "",
                    drivingWheels: "", doors: "", passengers: "", engine: "", fuel: "", baggage: "", steering: "", priceIncludes: "", insuranceConditions: [], orderDates: [],
                    isSubAgent: false, holderName: "", holderSurname: "", holderPhone: "", holderEmail: "", insuranceDate: "", insurancePrice: "", carNumber: ""
                });
                setShowAddCarsModal(true);
                setAction("add");
            }
        }
    ];

    const handlers = {
        search(keyword) {
            setKeyword(keyword);
        },
        addNew(e) {
            e.preventDefault();
            axios.post('/api/cars', formData)
                .then(() => {
                    setCars((prev) => ([{ ...formData }, ...prev]));
                    setShowAddCarsModal(false);
                    setNotification({ show: true, title: "Success", message: "Car added successfully" })
                })
                .catch((err) => {
                    const data = err.response.data;
                    setNotification({ show: true, title: data.description, message: data.message })
                })
        },
        deleteCar() {
            axios.delete(`/api/cars/${active.id}`)
                .then(() => {
                    const data = cars.filter((c) => c.id !== active.id);
                    setCars([...data]);
                    setShowDeleteCarsModal(false)
                })
                .catch((err) => {
                    const data = err.response.data;
                    setShowDeleteCarsModal(false)
                    setNotification({ show: true, title: data.description, message: data.message })
                })
        },
        editCars(e) {
            e.preventDefault();
            axios.put(`/api/cars/${active.id}`, formData)
                .then(() => {
                    const data = cars.map((c) => {
                        if (c.id === active.id) {
                            return { ...formData }
                        } else {
                            return { ...c }
                        }
                    })
                    setCars([...data]);
                    setShowAddCarsModal(false);
                    setNotification({ show: true, title: "Success", message: "Car edited successfully" })
                })
                .catch((err) => {
                    const data = err.response.data;
                    setNotification({ show: true, title: data.description, message: data.message })
                })
        }
    }

    const rowActions = [
        {
            name: "View bookings",
            id: 1005,
            action: (act) => {
                setFormData({ ...act });
                setShowBookingsModal(true);
            }
        },
        {
            name: "Edit",
            id: 1001,
            action: (act) => {
                setFormData({ ...act });
                setShowAddCarsModal(true);
                setActive({ ...act });
                setAction("edit");
            }
        },
        {
            name: "Delete",
            id: 1003,
            action: (act) => {
                setShowDeleteCarsModal(true);
                setActive({ ...act });
            }
        },
        {
            name: "Upload images",
            id: 1004,
            action: (act) => {
                setFormData({ ...act });
                setShowUploadImagesModal(true);
                setImages({ profile: act.profileImage, multiple: act.images })
            }
        }
    ];


    useEffect(() => {
        axios(`/api/cars?keyword=${keyword}&limit=${limit}&page=${page}`)
            .then((res) => {
                const data = res.data.data;
                setCars([...data]);
                setTotal(res.data.total);
            })
            .catch((err) => {
                const data = err.response.data;
                setNotification({ show: true, title: data.description, message: data.message })
            })
    }, [keyword, page, limit]);

    return (
        <div className="container">
            <div className="row">
                <div>
                    <DataTable
                        withoutSearchButton={false}
                        headers={headers}
                        searchHandler={handlers.search}
                        title={"Cars"}
                        actionButtons={actions}
                        limit={limit}
                        setLimit={setLimit}
                        limitControl={true}
                        data={cars}
                        hideSelects={true}
                        showParams={showParams}
                        pageChanged={page}
                        setPageChanged={setPage}
                        total={total}
                        rowActions={true}
                        rowActionsList={rowActions}
                        setActiveRow={setActive}
                        dateRange={false}
                    />
                </div>
                <Notification title={notification.title} message={notification.message} show={notification.show} setShow={setNotification} />
                <AddCarsModal show={showAddCarsModal} onSubmit={action === "add" ? handlers.addNew : handlers.editCars} hide={setShowAddCarsModal} formData={formData} setFormData={setFormData} action={action} />
                <Modal show={showDeleteCarsModal} onHide={() => setShowDeleteCarsModal(false)}>
                    <Modal.Header><h3>Delete Car</h3></Modal.Header>
                    <Modal.Body><p>Do you want to delete this car?</p></Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handlers.deleteCar}>Yes</Button>
                        <Button variant="info" onClick={() => setShowDeleteCarsModal(false)}>No</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showBookingsModal} onHide={() => setShowBookingsModal(false)} centered size="xl">
                    <Modal.Header><h5>Booking dates</h5></Modal.Header>
                    <Modal.Body>
                        <div className="date-filter-container">
                            <input type="date" className="form-control" onChange={(e) => setFilterDates((prev) => ({...prev, startDate: e.target.value}))} style={{maxWidth: "200px", marginRight: "20px"}} />
                            <input type="date" className="form-control" onChange={(e) => setFilterDates((prev) => ({...prev, endDate: e.target.value}))} style={{maxWidth: "200px", marginRight: "20px"}} />
                            <Button variant="dark" onClick={() => {
                                if(filterDates.startDate === "" || filterDates.endDate === ""){
                                    setNotification({show: true, title: "Failed", message: "Please select start and end date"})
                                }else{
                                    const hasOverlap = formData.orderDates.some((dateRange, k) => {
                                        const d = dateRangesOverlap(dateRange, filterDates);
                                        setBusy({...d});
                                        return d.isBusy
                                    });
                                    const sd = new Date(busy.startDate);
                                    const ed = new Date(busy.endDate);
                                    setNotification({show: true, title: "Status", message: hasOverlap ? `Selected date is busy. Order dates: ${sd.getFullYear()}/${sd.getMonth() + 1}/${sd.getDate()} - ${ed.getFullYear()}/${ed.getMonth() + 1}/${ed.getDate()}` : "Selected date is free"})
                                }
                            }}>Check</Button>
                        </div>
                        <div className="row">
                            {
                                formData.orderDates != null && formData.orderDates.slice(-30).map((o, k) => {
                                    const startDate = new Date(o.startDate);
                                    const endDate = new Date(o.endDate);
                                    return (
                                        <div key={k * 33} className="col-md-4" style={{ borderBottom: "1px solid gray" }}>
                                            <p style={{ display: "flex", alignItems: "center" }}><span style={{ width: "90px" }}>Start date:</span> {`${startDate.getFullYear()}/${startDate.getMonth() + 1}/${startDate.getDate()}`}</p>
                                            <p style={{ display: "flex", alignItems: "center" }}><span style={{ width: "90px" }}>End date:</span> {`${endDate.getFullYear()}/${endDate.getMonth() + 1}/${endDate.getDate()}`}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={showInsuranceModal} onHide={() => setShowInsuranceModal(false)} size={"lg"} centered>
                    <Modal.Header>
                        <h5>Insurance limits</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <ul className={"insurance-alert-list"}>
                            {insuranceReminder.map((i, k) => {
                                const date = new Date(i.insuranceDate);
                                return (
                                    <li key={k * 55} className={"insurance-alert-list-item"}>
                                        <span>{i.id}</span>
                                        <span>{i.mark}</span>
                                        <span>{i.model}</span>
                                        <span>{i.year}</span>
                                        <span>{i.carNumber}</span>
                                        <span>{date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}</span>
                                    </li>
                                )
                            })}
                        </ul>
                    </Modal.Body>
                </Modal>
                <UploadImages data={formData} show={showUploadImagesModal} setCars={setCars} setShow={setShowUploadImagesModal} images={images} setImages={setImages} />
            </div>
        </div>
    )
}

export default AdminCars