import { Button } from 'react-bootstrap'
import './Header.css'
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import {FaBars} from "react-icons/fa"
import axios from 'axios'

const Header = ({ role, setIsOpen, isOpen }) => {
    return (
        <header className='header-container'>
            <div className="container">
                <div className="container-content">
                    <Link to="/"><img style={{ height: "80px" }} alt='logo' src="https://crg.ge/static/icons/logo.svg" /></Link>
                    <nav className='navbar-container'>
                        {(role !== "admin" && role !== "moderator") && <ul className='navbar-items'>
                            <li><Link to="/cars">Cars</Link></li>
                            <li><Link to="/driver-guide">Driver Guide</Link></li>
                            <li><Link to="/bicycle">Bicycle</Link></li>
                            <li><Link to="/moped">Moped</Link></li>
                            <li><Link to="/conditions">Conditions</Link></li>
                            <li><Link to="/about-us">About Us</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>}
                        {(role === "admin" && (
                            <>
                                <ul className='navbar-items'>
                                    <li><Link to="/admin/cars">Cars</Link></li>
                                    <li><Link to="/admin/booking">Booking</Link></li>
                                    <li><Link to="/admin/payments">Payments</Link></li>
                                    <li><Link to="/admin/charges">Expenses</Link></li>
                                    <li><Link to="/admin/expences">Charges</Link></li>
                                    <li><Link to="/admin/operators">Operators</Link></li>
                                </ul>
                                <Button variant="dark" style={{marginLeft: "40px"}} className='log-out-button' onClick={() => {
                                    axios.post('/api/users/logout')
                                        .then((res) => {
                                            window.location.href = "/"
                                        })  
                                        .catch((err) => {
                                            console.log(err)
                                        })
                                }}>Log out</Button>
                            </>
                        ))}
                        {(role === "moderator" && (
                            <ul className='navbar-items'>
                                <li><Link to="/admin/cars">Cars</Link></li>
                                <li><Link to="/admin/booking">Booking</Link></li>
                                <li><Link to="/admin/charges">Expenses</Link></li>
                                <Button variant="dark" style={{marginLeft: "40px"}} className='log-out-button' onClick={() => {
                                    axios.post('/api/users/logout')
                                        .then((res) => {
                                            window.location.href = "/"
                                        })  
                                        .catch((err) => {
                                            console.log(err)
                                        })
                                }}>Log out</Button>
                            </ul>
                        ))}
                    </nav>
                    <button className="burger-button" onClick={() => setIsOpen(!isOpen)}><FaBars/></button>
                </div>
            </div>
        </header>
    )
}

export default Header