import { useState } from "react"
import './AdminAuth.css'
import { Button } from 'react-bootstrap'
import axios from "axios";
import Notification from "../../../Hooks/Notification";
import {useSelector, useDispatch} from 'react-redux';
import { usersActions } from "../../../store/user";

const AdminAuth = () => {
    const [formData, setFormData] = useState({ username: "", password: "" });
    const [notification, setNotifications] = useState({ show: false, title: "", message: "" });
    const dispatch = useDispatch();

    const handlers = {
        async auth(e) {
            e.preventDefault();
            try {
                const response = await axios.post(`/api/users/auth`, formData);
                const data = response.data.data;
                dispatch(usersActions.changeIsLoggedIn(true));
                dispatch(usersActions.setLoggedInHandler({
                    name: data.name, surname: data.surname, username: data.username, role: data.role, email: data.email
                }));
                window.location.href = '/admin/cars'
            } catch (err) {
                const { description, message } = err.response.data;
                setNotifications({ show: true, title: description, message: message })
            }
        }
    }

    return (
        <>
            <div className="admin-auth-container">
                <div className="auth-box">
                    <h3 className="auth-title">Log In</h3>
                    <form className="auth-form" onSubmit={handlers.auth}>
                        <div className="form-group">
                            <label>Username:</label>
                            <input type="text" className="form-control" required placeholder="Enter username" onChange={(e) => setFormData((prev) => ({ ...prev, username: e.target.value }))} />
                        </div>
                        <div className="form-group">
                            <label>Username:</label>
                            <input type="password" className="form-control" required placeholder="Enter password" onChange={(e) => setFormData((prev) => ({ ...prev, password: e.target.value }))} />
                        </div>
                        <Button style={{ width: "100%" }} variant="dark" type="submit">Log In</Button>
                    </form>
                </div>
            </div>
            <Notification show={notification.show} title={notification.title} setShow={setNotifications} message={notification.message}/>
        </>
    )
}

export default AdminAuth