import React from "react";
import { pushRotate as Menu } from "react-burger-menu";
import "./Sidebar.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "react-bootstrap";
import axios from "axios";


const Sidebar = ({ role, isOpen, setIsOpen }) => {

    const handleMenuStateChange = (state) => {
        setIsOpen(state.isOpen);
    };

    return (
        <div>
            {role === "client" && <Menu
                isOpen={isOpen}
                onStateChange={handleMenuStateChange}
                right
                width={250} // Adjust the width as per your requirements
            >
                <Link className="sidebar-text" to="/cars">
                    Cars
                </Link>
                <Link className="sidebar-text" to="/driver-guide">
                    Driver Guide
                </Link>
                <Link className="sidebar-text" to="/bicycle">
                    Bicycle
                </Link>
                <Link className="sidebar-text" to="/moped">
                    Moped
                </Link>
                <Link className="sidebar-text" to="/conditions">
                    Conditions
                </Link>
                <Link className="sidebar-text" to="/about-us">
                    About Us
                </Link>
                <Link className="sidebar-text" to="/contact">
                    Contact
                </Link>
            </Menu>}
            {(role === "admin" && (
                <Menu
                    isOpen={isOpen}
                    onStateChange={handleMenuStateChange}
                    right
                    width={250}
                >
                    <Link className="sidebar-text" to="/admin/cars">Cars</Link>
                    <Link className="sidebar-text" to="/admin/booking">Booking</Link>
                    <Link className="sidebar-text" to="/admin/payments">Payments</Link>
                    <Link className="sidebar-text" to="/admin/charges">Expenses</Link>
                    <Link className="sidebar-text" to="/admin/expences">Charges</Link>
                    <Button variant="dark" style={{ marginTop: "90px" }} className='log-out-button' onClick={() => {
                        axios.post('/api/users/logout')
                            .then((res) => {
                                window.location.href = "/"
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }}>Log out</Button>
                </Menu>
            ))}
            {(role === "moderator" && (
                <Menu
                    isOpen={isOpen}
                    onStateChange={handleMenuStateChange}
                    right
                    width={250}
                >
                    <Link className="sidebar-text" to="/admin/cars">Cars</Link>
                    <Link className="sidebar-text" to="/admin/booking">Booking</Link>
                    <Link className="sidebar-text" to="/admin/charges">Charges</Link>
                </Menu>
            ))}
        </div>
    );
};

export default Sidebar;