import { Link } from "react-router-dom/cjs/react-router-dom.min"
import './CarsElement.css'

const CarElement = ({ data,filter }) => {
    return (
        <Link to={`/car/${data.id}?${filter ? `${(typeof filter.pickup !== "undefined" && filter.pickup !== "") ? `pickup=${filter.pickup}&` : ''}${(typeof filter.dropoff !== "undefined" && filter.dropoff !== "") ? `dropoff=${filter.dropoff}&` : ''}${(typeof filter.start !== "undefined" && filter.start !== "") ? `start=${filter.start}&` : ''}${(typeof filter.end !== "undefined" && filter.end !== "") ? `end=${filter.end}&` : ''}` : ''}`} className="col-md-3 car-item-container">
            <div className="car-container">
                <div className="car-image" style={{ backgroundImage: `url(${data.profileImage})` }}></div>
                <p className="car-info">{data.mark} {data.model}</p>
                <div className="car-details">
                    <div className="flex-column-left">
                        <p>Type: {data.category}</p>
                        <p>Class: {data.carClass}</p>
                    </div>
                    <div className="car-price-info">
                        <h2>{data.startingPrice}</h2>
                        <div className="currency">
                            <span>EUR</span>
                            <p>From</p>
                        </div>
                    </div>
                </div>
                <button className="details-button">Details</button>
            </div>
        </Link>
    )
}

export default CarElement