import { Redirect, Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import './App.css';
import BannerSlider from './Common/BannerSlider';
import Header from './Common/Header';
import CarSearchFilter from './Components/CarSearchFilter/CarSearchFilter';
import Main from './Pages/Main';
import AboutUs from './Pages/AboutUs';
import Footer from './Common/Footer';
import Cars from './Pages/Cars';
import DriverGuide from './Pages/DriverGuide';
import Bicycle from './Pages/Bicycles';
import Moped from './Pages/Moped';
import TermsAndConditions from './Pages/TermsAndConditions';
import AdminAuth from './Components/Admin/Auth/AdminAuth';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usersActions } from './store/user';
import axios from 'axios';
import AdminCars from './Components/Admin/Cars/AdminCars';
import AdminBooking from './Components/Admin/Booking/AdminBooking';
import AdminPayments from './Components/Admin/Payments/AdminPayments';
import AdminExpenses from './Components/Admin/expenses/Expenses';
import CarPage from './Pages/CarPage';
import Sidebar from './Common/Sidebar';
import Contact from "./Pages/Contact";
import Charges from "./Components/Admin/Charges/Charges";
import Operators from './Components/Admin/Operators';
import MessengerCustomerChat from 'react-messenger-customer-chat';

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    axios.get(`/api/user`)
      .then((res) => {
        const data = res.data.data;
        dispatch(usersActions.changeIsLoggedIn(true));
        dispatch(usersActions.setLoggedInHandler({
          name: data.name, surname: data.surname, username: data.username, role: data.role, email: data.email
        }));
      })
      .catch((err) => {
        dispatch(usersActions.setLoggedInHandler({
          name: "", surname: "", username: "", role: "client", email: ""
        }));
      })
  }, [])

  return (
    <div className="App" id="outer-container">
      <Sidebar role={user.role} pageWrapID={"page-wrap"} isOpen={isOpen} setIsOpen={setIsOpen} outerContainerId={"outer-container"} />
      <Header role={user.role} isOpen={isOpen} setIsOpen={setIsOpen} />
      <div style={{ paddingTop: "80px" }}>
        <Switch>
          <Route path='/' exact>
            <Main />
          </Route>
          <Route path='/about-us' exact>
            <AboutUs />
          </Route>
          <Route path='/cars' exact>
            <Cars />
          </Route>
          <Route path='/car/:id' exact>
            <CarPage />
          </Route>
          <Route path='/driver-guide' exact>
            <DriverGuide />
          </Route>
          <Route path='/bicycle' exact>
            <Bicycle />
          </Route>
          <Route path='/moped' exact>
            <Moped />
          </Route>
          <Route path='/conditions' exact>
            <TermsAndConditions />
          </Route>
          <Route path={"/contact"}>
            <Contact />
          </Route>
          <Route path='/admin/108070' exact>
            {(user.role === "client" || user.role === "") && <AdminAuth />}
          </Route>
          <Route path='/admin'>
            {user.role === "admin" && <>
              {user.role === "client" && <Redirect to="/admin/108070" />}
              <Route path='/admin/cars' exact>
                <AdminCars />
              </Route>
              <Route path='/admin/booking' exact>
                <AdminBooking role={user.role} />
              </Route>
              <Route path='/admin/payments' exact>
                <AdminPayments />
              </Route>
              <Route path='/admin/charges' exact>
                <AdminExpenses role={user.role} />
              </Route>
              <Route path='/admin/expences' exact>
                <Charges />
              </Route>
              <Route path='/admin/operators' exact>
                <Operators />
              </Route>
            </>}
            {user.role === "moderator" && <>
              {user.role === "client" && <Redirect to="/admin/108070" />}
              <Route path='/admin/cars' exact>
                <AdminCars />
              </Route>
              <Route path='/admin/booking' exact>
                <AdminBooking role={user.role} />
              </Route>
              <Route path='/admin/payments' exact>
                <AdminPayments />
              </Route>
              <Route path='/admin/charges' exact>
                <AdminExpenses role={user.role} />
              </Route>
            </>}
          </Route>
        </Switch>
      </div>
      <MessengerCustomerChat
        pageId="395018107708043"
        appId="295029786639743"
      />
      {!(user.role === "admin" || user.role === "moderator") && <Footer />}
    </div>
  );
}

export default App;
