import { useState } from "react";
const { Modal, Button } = require("react-bootstrap")

const AddExpenseModal = ({ show, hide, formData, setFormData, action, onSubmit }) => {

    return (
        show && <Modal show={show} onHide={() => hide(false)} size={"md"} centered>
            <Modal.Header><h4>{action === "add" ? "Add new charge" : "Edit charge"}</h4></Modal.Header>
            <Modal.Body>
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="form-group">
                            <label>Date</label>
                            <input
                                type="date"
                                className="form-control"
                                onChange={(e) => setFormData((prev) => {
                                    const date = new Date(e.target.value);
                                    return {...prev, date: `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`}
                                })}
                                value={formData.date}
                            />
                        </div>
                        <div className="form-group">
                            <label>Charge type</label>
                            <input
                                type="text"
                                className="form-control"
                                onChange={(e) => setFormData((prev) => {
                                    return {...prev, chargeType: e.target.value}
                                })}
                                value={formData.chargeType}
                            />
                        </div>
                        <div className="form-group">
                            <label>Description</label>
                            <textarea
                                className="form-control"
                                onChange={(e) => setFormData((prev) => {
                                    return {...prev, description: e.target.value}
                                })}
                                value={formData.description}
                            />
                        </div>
                        <div className="form-group">
                            <label>Amount</label>
                            <input
                                type="text"
                                className="form-control"
                                onChange={(e) => setFormData((prev) => {
                                    return {...prev, amount: e.target.value}
                                })}
                                value={formData.amount}
                            />
                        </div>
                        <Button variant="dark" type="submit">{action === "add" ? "Add new" : "Save"}</Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default AddExpenseModal