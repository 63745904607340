import { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import './CarSearchFilter.css'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';

const CarSearchFilter = () => {
    const [data, setData] = useState({ model: [], mark: [] });
    const dates = new Date();
    const today = `${dates.getFullYear()}-${dates.getMonth() + 1}-${dates.getDate() < 10 ? `0${dates.getDate()}` : dates.getDate()}`;
    const [cars, setCars] = useState([]);
    const [formData, setFormData] = useState({
        pickupLocation: "",
        dropOffLocation: "",
        mark: "",
        model: "",
        category: "cars",
        pickupDate: "",
        dropOffDate: ""
    });
    const [days, setDays] = useState({ start: 0, end: 0, count: 1 });
    const [minimumDate, setMinimumDate] = useState("");

    useEffect(() => {
        let type = formData.category;
        if (type === "cars") type = "car";
        if (type === "driver-guide") type = "Minivan";
        if (type === "bicycle") type = "Bike";

        axios(`/api/product?limit=400&page=1&type=${type}&location=&mark=&model=`)
            .then((res) => {
                let d = res.data.data;
                d = d.filter((c) => c.mark !== "");
                setCars([...d]);
                d = d.map((m) => (m.mark));

                const unique = new Set(d);
                const u = [...unique];
                setData((prev) => ({ ...prev, mark: [...u] }));
            })
            .catch((err) => console.log(err))
    }, [formData.category])

    return (
        <div className="search-cars-container container">
            <h3 className='section-title'>Choose Car For You</h3>
            <form className="search-cars-form">
                <div className='row'>
                    <div className='col-md-3'>
                        <div className="form-group">
                            <label>Pickup Location</label>
                            <select value={formData.pickupLocation} className='form-control' onChange={(e) => setFormData((prev) => ({ ...prev, pickupLocation: e.target.value }))} >
                                <option>Select location</option>
                                <option value={"Kutaisi Center"}>Kutaisi Center</option>
                                <option value={"Kutaisi Airport"}>Kutaisi Airport</option>
                                <option value={"Tbilisi Center"}>Tbilisi Center</option>
                                <option value={"Tbilisi Airport"}>Tbilisi Airport</option>
                                <option value={"Batumi Center"}>Batumi Center</option>
                                <option value={"Batumi Airport"}>Batumi Airport</option>
                                <option value={"Other"}>At All Other Places</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Category</label>
                            <select className='form-control' value={formData.category} onChange={(e) => setFormData((prev) => ({ ...prev, category: e.target.value }))}>
                                <option value={"cars"}>Car</option>
                                <option value={"bicycle"}>Bike</option>
                                <option value={"moped"}>Moped</option>
                                <option value={"driver-guide"}>Driver guide</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="form-group">
                            <label>Mark</label>
                            <select value={formData.mark} className='form-control' onChange={(e) => {
                                let models = cars.filter((c) => c.mark === e.target.value);
                                models = models.map((m) => (m.model));
                                const unique = new Set(models);
                                const u = [...unique];
                                setData((prev) => ({ ...prev, model: [...u] }));
                                setFormData((prev) => ({ ...prev, mark: e.target.value }));
                            }} >
                                <option>Select mark</option>
                                {
                                    data.mark.length > 0 && data.mark.map((m, k) => <option key={k * 2} value={m}>{m}</option>)
                                }
                            </select>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="form-group">
                            <label>Model</label>
                            <select value={formData.model} className='form-control' onChange={(e) => setFormData((prev) => ({ ...prev, model: e.target.value }))} >
                                <option>Select model</option>
                                {
                                    data.model.length > 0 && data.model.map((m, k) => <option key={k * 100} value={m}>{m}</option>)
                                }
                            </select>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="form-group">
                            <label>Dropoff Location</label>
                            <select value={formData.dropOffLocation} className='form-control' onChange={(e) => setFormData((prev) => ({ ...prev, dropOffLocation: e.target.value }))} >
                                <option>Select location</option>
                                <option value={"Kutaisi Center"}>Kutaisi Center</option>
                                <option value={"Kutaisi Airport"}>Kutaisi Airport</option>
                                <option value={"Tbilisi Center"}>Tbilisi Center</option>
                                <option value={"Tbilisi Airport"}>Tbilisi Airport</option>
                                <option value={"Batumi Center"}>Batumi Center</option>
                                <option value={"Batumi Airport"}>Batumi Airport</option>
                                <option value={"Other"}>At All Other Places</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Pickup Date</label>
                            <input type='datetime-local' className='form-control' min={`${today}T00:00`} onChange={(e) => {
                                const d = new Date(e.target.value);
                                const selected = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate() + 1 < 10 ? `0${d.getDate() + 1}` : d.getDate() + 1}`;
                                setMinimumDate(selected);
                                const date = +new Date(e.target.value);
                                setDays((prev) => ({ ...prev, start: date }))
                            }} />
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Dropoff Date</label>
                            <input type='datetime-local' min={`${minimumDate}T00:00`} className='form-control' onChange={(e) => {
                                const date = +new Date(e.target.value);
                                setDays((prev) => ({ ...prev, end: date }))
                            }} />
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group' style={{ marginTop: "24px" }}>
                            <button type='button' onClick={() => {
                                if (days.start === 0 || days.end === 0) {
                                    window.location.href = `/${formData.category}?pickup=${formData.pickupLocation}&dropoff=${formData.dropOffLocation}&mark=${formData.mark}&model=${formData.model}&count=1&start=${days.start}&end=${days.end}`;
                                } else {
                                    const counter = (days.end - days.start) / 86400000;
                                    window.location.href = `/${formData.category}?pickup=${formData.pickupLocation}&dropoff=${formData.dropOffLocation}&mark=${formData.mark}&model=${formData.model}&count=${counter}&start=${days.start}&end=${days.end}`;
                                }
                            }} className='submit-button'>Filter</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CarSearchFilter