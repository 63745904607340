import { useEffect, useState } from 'react';
import './BannerSlider.css';
import { FcPrevious, FcNext } from 'react-icons/fc';

const BannerSlider = ({ icon, images, withButtons, actionButtonStyles, autoPlay, autoPlayInterval }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [slideChangeEvent, setSlideChangeEvent] = useState({ action: 0, events: false });

    const handlers = {
        prevSlide() {
            const imagesCount = images.length;
            if (currentSlide === 0) {
                setCurrentSlide(imagesCount - 1);
                setZoom(0.95);
                setTimeout(() => {
                    setZoom(1)
                }, 600)
            } else {
                setCurrentSlide((prev) => prev - 1);
                setZoom(0.95);
                setTimeout(() => {
                    setZoom(1)
                }, 600)
            }
            setSlideChangeEvent((prev) => ({ action: prev.action + 1, events: true }))
        },
        nextSlide() {
            const imagesCount = images.length;
            if (currentSlide === imagesCount - 1) {
                setCurrentSlide(0);
                setZoom(0.95);
                setTimeout(() => {
                    setZoom(1)
                }, 600)
            } else {
                setCurrentSlide((prev) => prev + 1);
                setZoom(0.95);
                setTimeout(() => {
                    setZoom(1)
                }, 600)
            }
            setSlideChangeEvent((prev) => ({ action: prev.action + 1, events: true }))
        }
    }

    useEffect(() => {
        let index = 0;
        if (slideChangeEvent.events) {
            return index = currentSlide;
        }
        if (autoPlay) {
            setInterval(() => {
                const imagesCount = images.length;

                if (index === imagesCount - 1) {
                    setCurrentSlide(0);
                    index = 0;
                    // setZoom(0.95);
                    setTimeout(() => {
                        setZoom(1)
                    }, 600);
                } else {
                    setCurrentSlide((prev) => prev + 1);
                    index++;
                    // setZoom(0.95);
                    setTimeout(() => {
                        setZoom(1)
                    }, 600)
                }
            }, autoPlayInterval)
        }
    }, [slideChangeEvent, autoPlay, autoPlayInterval, images.length]);

    return (
        <div className="carousel-container">
            {withButtons === false && <>
                <button onClick={handlers.prevSlide} className='action-buttons' style={actionButtonStyles ? actionButtonStyles : { left: "30px" }}><FcPrevious style={{ fontSize: "2.5rem" }} /></button>
                <button onClick={handlers.nextSlide} className='action-buttons' style={actionButtonStyles ? actionButtonStyles : { right: "30px" }}><FcNext style={{ fontSize: "2.5rem" }} /></button>
            </> }
            <div className="images-list">
                <div className="slider-container" style={{ marginLeft: `-${currentSlide * 100}vw`, transition: "1.5s" }}>
                    {images.map((i, l) => {
                        return (
                            <div className="slider-item" key={l * 8}>
                                <img src={i.img} alt="slider-carousel" className="slider-item-image" style={{ transform: `scale(${zoom})` }} />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default BannerSlider