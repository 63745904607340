import './Services.css'
import car from './car.svg'
import bike from './bike.svg'
import moped from './moped.svg'

const Services = () => {
    return (
        <>
            <div className="services-list row" style={{marginBottom: "10px", marginTop: "-140px", position: "relative", paddingTop: "7px"}}>
                <div className="col-md-4 service">
                    <div className='service-image-box'>
                        <img className="services-icon" src={car} alt='services' />
                    </div>
                    <p className='services-slogan'>Low Car Prices</p>
                </div>
                <div className="col-md-4 service">
                    <div className='service-image-box'>
                        <img className="services-icon" src={bike} alt='services'/>
                    </div>
                    <p className='services-slogan'>Low Bike Prices</p>
                </div>
                <div className="col-md-4 service">
                    <div className='service-image-box'>
                        <img className="services-icon" src={moped}  alt='services'/>
                    </div>
                    <p className='services-slogan'>Low Moped Prices</p>
                </div>
            </div>
        </>
    )
}

export default Services