import { Link } from "react-router-dom/cjs/react-router-dom.min"

const Footer = () => {
    return (
        <footer className="footer-container">
            <div className="container">
                <div className="row">
                    <div className="col-md-4"><Link to="/"><img className="footer-logo" src="https://crg.ge/static/icons/logo.svg" alt='logo' /></Link></div>
                    <div className="col-md-4">
                        <div className="footer-office-address">
                            <p style={{ marginBottom: "13px", fontWeight: "bold" }}>Our Address</p>
                            <p>Georgia, 4600</p>
                            <p>KUTAISI Galaktion Tabidze STR. 23</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="footer-contact">
                            <p style={{ marginBottom: "13px", fontWeight: "bold" }}>Contact Information</p>
                            <p>PHONE: <a style={{marginLeft: "8px"}} href="tel:+995555534949">+995 555 53 49 49</a></p>
                            <p>E-MAIL: <a style={{marginLeft: "10px"}} href="mailto:info.carrentingeorgia@gmail.com">info.carrentingeorgia@gmail.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer