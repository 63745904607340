import axios from "axios";
import { useState } from "react"
import { Modal } from "react-bootstrap";

const UploadImages = ({ show, setShow, data, images, setCars, setImages }) => {
    const [profileImage, setProfileImage] = useState(null);
    const [multipleImages, setMultipleImages] = useState(null);
    const [selectedProfileImage, setSelectedProfileImage] = useState(null);
    const [selectedMultipleImage, setSelectedMultipleImage] = useState([]);
    const [loading, setLoading] = useState({ profile: false, multiple: false });

    const handleImageChange = (e) => {
        setMultipleImages(e.target.files)
        const files = e.target.files;
        const imageArray = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();

            reader.onload = (event) => {
                imageArray.push(event.target.result);

                // If all images have been processed, update the state
                if (imageArray.length === files.length) {
                    setSelectedMultipleImage(imageArray);
                }
            };

            reader.readAsDataURL(file);
        }
    };

    return (
        <Modal show={show} onHide={() => setShow(false)} size="lg" centered>
            <Modal.Header><h4>Upload images</h4></Modal.Header>
            <Modal.Body>
                <p className="modal-section-title">Profile Image</p>
                <div className="upload-images-box">
                    {selectedProfileImage && <img
                        src={selectedProfileImage}
                        className="profile-image-on-modal"
                        alt="profile image selected"
                    />}
                    {(images.profile !== "" && !selectedProfileImage && images.profile) && <img
                        src={images.profile}
                        className="profile-image-on-modal"
                        alt="profile image uploaded"
                    />}
                    <input id="upload-profile-image" type="file" hidden onChange={(e) => {
                        const file = e.target.files[0];
                        setProfileImage(e.target.files);
                        const reader = new FileReader();

                        reader.onload = (event) => {
                            setSelectedProfileImage(event.target.result);
                        };

                        reader.readAsDataURL(file);
                    }} />
                    <label htmlFor="upload-profile-image" className="upload-file-button">Select Image</label>
                    <button style={{ marginTop: "10px", background: "#0a56f7" }} disabled={loading.profile} className="upload-file-button" onClick={() => {
                        setLoading((prev) => ({...prev, profile: true}));
                        const formData = new FormData();
                        formData.append("images", profileImage[0]);
                        formData.append("id", data.id);
                        formData.append("type", "profile");
                        axios.post(`/api/cars/images`, formData)
                            .then((res) => {
                                alert("success");
                                const url = res.data.data[0];
                                setSelectedProfileImage(null);
                                setLoading((prev) => ({...prev, profile: false}));
                                setCars((prev) => {
                                    const d = prev.map((c) => {
                                        if(c.id === data.id){
                                            console.log(c.id)
                                            return {...c, profileImage: url}
                                        }else{
                                            return {...c}
                                        }
                                    });
                                    return [...d];
                                });
                                setImages((prev) => ({...prev, profile: url}))
                            })
                            .catch((err) => {
                                alert("failed");
                                setLoading((prev) => ({...prev, profile: false}));
                            })
                    }}>Upload</button>
                </div>
                <p className="modal-section-title">Multiple Image</p>
                <div className="upload-images-box">
                    {selectedMultipleImage.length > 0 && (
                        <div>
                            <h2>Selected Images:</h2>
                            <ul>
                                {selectedMultipleImage.map((image, index) => (
                                    <li key={index}>
                                        <img className="profile-image-on-modal" src={image} alt={`Selected Image ${index + 1}`} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {(images.multiple && !selectedMultipleImage.length > 0) && (
                        <div>
                            <h2>Uploaded Images:</h2>
                            <ul>
                                {images.multiple.map((image, index) => (
                                    <li key={index}>
                                        <img className="profile-image-on-modal" src={image} alt={`Selected Image ${index + 1}`} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <input id="upload-multiple-image" multiple type="file" hidden onChange={handleImageChange} />
                    <label htmlFor="upload-multiple-image" className="upload-file-button">Select Image</label>
                    <button style={{ marginTop: "10px", background: "#0a56f7" }} disabled={loading.multiple} className="upload-file-button" onClick={() => {
                        setLoading((prev) => ({...prev, multiple: true}));
                        const formData = new FormData();
                        for (let i = 0; i < multipleImages.length; i++) {
                            formData.append('images', multipleImages[i]);
                        }
                        formData.append("id", data.id);
                        formData.append("type", "multiple");
                        axios.post(`/api/cars/images`, formData)
                            .then((res) => {
                                alert("success");
                                const url = res.data.data;
                                setLoading((prev) => ({...prev, multiple: false}));
                                setCars((prev) => {
                                    const d = prev.map((c) => {
                                        if(c.id === data.id){
                                            return {...c, images: [...url]}
                                        }else{
                                            return {...c}
                                        }
                                    });
                                    return [...d];
                                });
                                setSelectedMultipleImage([]);
                                setImages((prev) => ({...prev, multiple: [...url]}))
                            })
                            .catch((err) => {
                                alert("failed");
                                setLoading((prev) => ({...prev, multiple: false}))
                            })
                    }}>Upload</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default UploadImages