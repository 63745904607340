import axios from "axios";
import { useEffect, useState } from "react";
import DataTable from "../../../Common/DataTable/DataTable";
import { Button, Modal } from "react-bootstrap";
import Notification from "../../../Hooks/Notification";
import AddBookingModal from "../Modals/AddBookingModal";
import OrderImagesModal from "../Modals/OrderImagesModal";

const AdminBooking = ({role}) => {
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [sort, setSort] = useState("id");
    const [keyword, setKeyword] = useState("");
    const [bookings, setBookings] = useState([]);
    const [showApplyModal, setShowApplyModal] = useState(false);
    const [notification, setNotification] = useState({ show: false, title: "", message: "" });
    const [showAddBookingsModal, setShowAddBookingsModal] = useState(false);
    const [action, setAction] = useState("add");
    const [showUploadOrderImages, setShowUploadOrderImages] = useState(false);
    const [formData, setFormData] = useState({
        pickupLocation: "", pickupDate: "", pickupTime: "", dropOffDate: "", dropOffTime: "", dropOffLocation: "", mark: "", model: "", orderedCarId: "",
        carsQuantity: 1, priceSum: "", year: "", accept: "", currency: "EUR", gps: false, babySet: false, topBag: false, mp3Connector: false, skyCarRack: false,
        wifiPack: false, tents2x: 0, tents3x: 0, sleepingBag: 0, sleepingBag3x: 0, paralon: 0, msrSuperFly: false, fishingRod: false, driverInCity: false,
        driverOutOfCity: false, name: "", surname: "", email: "", age: "", phone: "", note: "", paymentMethod: "", income: 0
    });
    const [showDeleteBookingsModal, setShowDeleteBookingsModal] = useState(false);
    const [active, setActive] = useState({});
    const [totalIncome, setTotalIncome] = useState("");

    const headers = ["Name", "Surname", "Pickup", "Dropoff", "Phone", "Mark", "Model", "Year", "Car ID", "Price Sum", ""];
    const showParams = [
        { type: "string", name: "name" }, { type: "string", name: "surname" }, { type: "date", name: "pickupDate" }, { type: "date", name: "dropOffDate" }, { type: "string", name: "phone" }, { type: "string", name: "mark" },
        { type: "string", name: "model" }, { type: "string", name: "year" }, { type: "string", name: "orderedCarId" }, { type: "string", name: "priceSum" }
    ];
    const [images, setImages] = useState({ rental: [], receipt: [] });

    const actions = [
        {
            name: "Add new",
            action: () => {
                setFormData({
                    pickupLocation: "", pickupDate: "", pickupTime: "", dropOffDate: "", dropOffTime: "", dropOffLocation: "", mark: "", model: "", orderedCarId: "",
                    carsQuantity: 1, priceSum: "", year: "", accept: "", currency: "EUR", gps: false, babySet: false, topBag: false, mp3Connector: false, skyCarRack: false,
                    wifiPack: false, tents2x: false, tents3x: false, sleepingBag: false, sleepingBag3x: false, paralon: false, msrSuperFly: false, fishingRod: false, driverInCity: false,
                    driverOutOfCity: false, name: "", surname: "", email: "", age: "", phone: "", note: "", paymentMethod: "", income: 0
                });
                setShowAddBookingsModal(true);
                setAction("add");
            }
        }
    ];

    const handlers = {
        search(keyword) {
            setKeyword(keyword);
        },
        addNew(e) {
            e.preventDefault();
            axios.post(`/api/cars/${formData.id}/booking`, formData)
                .then((res) => {
                    const data = res.data;
                    setBookings((prev) => [{ ...formData }, ...prev]);
                    setShowAddBookingsModal(false);
                    setNotification({ show: true, title: data.status, message: data.message })
                })
                .catch((err) => {
                    const data = err.response.data;
                    setNotification({ show: true, title: data.description, message: data.message })
                })
        },
        deleteBooking() {
            axios.delete(`/api/booking/${formData.id}`)
                .then(() => {
                    setShowDeleteBookingsModal(false);
                    setNotification({ show: true, title: "Success", message: "Booking removed successfully" });
                    const data = bookings.filter((b) => b.id !== formData.id);
                    setBookings([...data]);
                })
                .catch((err) => {
                    const data = err.response.data;
                    setNotification({ show: true, title: data.description, message: data.message });
                    setShowAddBookingsModal(false);
                })
        },
        editBooking(e) {
            e.preventDefault();
            axios.put(`/api/booking/${formData.id}`, formData)
                .then(() => {
                    const data = bookings.map((c) => {
                        if (c.id === formData.id) {
                            return { ...formData }
                        } else {
                            return { ...c }
                        }
                    })
                    setBookings([...data]);
                    setShowAddBookingsModal(false);
                })
                .catch((err) => {
                    const data = err.response.data;
                    setNotification({ show: true, title: data.description, message: data.message });
                    setShowAddBookingsModal(false);
                })
        },
        applyBooking(act) {
            const data = {
                startDate: act.pickupDate, endDate: act.dropOffDate, price: act.priceSum, income: act.income,
                name: act.name, surname: act.surname, email: act.email, mark: act.mark, model: act.model, type: act.orderType
            };
            setShowApplyModal(false);
            axios.put(`/api/cars/${act.orderedCarId}/booking/status`, data)
                .then((res) => {
                    const d = res.data;
                    setNotification({ show: true, title: d.description, message: d.message })
                })
                .catch((err) => {
                    const d = err.response.data;
                    setNotification({ show: true, title: d.description, message: d.message })
                })
        }
    }

    const rowActions = role === "admin" ? ([
        {
            name: "Approve",
            id: 1000,
            action: (act) => {
                setActive({...act});
                setShowApplyModal(true);
            }
        },
        {
            name: "Edit",
            id: 1001,
            action: (act) => {
                setFormData({ ...act });
                setShowAddBookingsModal(true)
                setAction("edit");
            }
        },
        {
            name: "Delete",
            id: 1003,
            action: (act) => {
                setFormData({ ...act });
                setShowDeleteBookingsModal(true);
            }
        },
        {
            name: "Order Images",
            id: 1004,
            action: (act) => {
                setFormData({ ...act });
                setImages({ rental: act.rentalImages, receipt: act.receiveImages });
                setShowUploadOrderImages(true);
            }
        }
    ]) : (
        [
            {
                name: "Edit",
                id: 1001,
                action: (act) => {
                    setFormData({ ...act });
                    setShowAddBookingsModal(true)
                    setAction("edit");
                }
            },
            {
                name: "Order Images",
                id: 1004,
                action: (act) => {
                    setFormData({ ...act });
                    setImages({ rental: act.rentalImages, receipt: act.receiveImages });
                    setShowUploadOrderImages(true);
                }
            }
        ]
    );

    useEffect(() => {
        axios(`/api/bookings?keyword=${keyword}&limit=${limit}&page=${page}&sort=${sort}`)
            .then((res) => {
                const data = res.data.data;
                setBookings([...data]);
                setTotal(res.data.total);
                setTotalIncome(res.data.priceSum)
            })
            .catch((err) => {
                const data = err.response.data;
                setNotification({ show: true, title: data.description, message: data.message })
            })
    }, [keyword, page, limit, sort]);

    return (
        <div className="container">
            <div className="row">
                <div className="additional-filters">
                    <select className="form-control" style={{maxWidth: "360px"}} onChange={(e) => setSort(e.target.value)}>
                        <option value={"id"}>Sort By</option>
                        <option value={"pickupDate"}>Pickup Date</option>
                        <option value={"dropOffDate"}>Dropoff Date</option>
                    </select>
                </div>
                <div>
                    <DataTable
                        withoutSearchButton={false}
                        headers={headers}
                        searchHandler={handlers.search}
                        title={"Bookings"}
                        actionButtons={actions}
                        limit={limit}
                        hideSelects={true}
                        setLimit={setLimit}
                        limitControl={true}
                        data={bookings}
                        showParams={showParams}
                        pageChanged={page}
                        setPageChanged={setPage}
                        total={total}
                        rowActions={true}
                        rowActionsList={rowActions}
                        setActiveRow={setActive}
                    />
                    <div>
                        <p>Total income: <strong>{totalIncome} EUR</strong></p>
                    </div>
                </div>
                <Notification title={notification.title} message={notification.message} show={notification.show} setShow={setNotification} />
                <AddBookingModal show={showAddBookingsModal} onSubmit={action === "add" ? handlers.addNew : handlers.editBooking} hide={setShowAddBookingsModal} formData={formData} setFormData={setFormData} action={action} />
                <Modal show={showDeleteBookingsModal} onHide={() => setShowDeleteBookingsModal(false)}>
                    <Modal.Header><h3>Delete Booking</h3></Modal.Header>
                    <Modal.Body><p>Do you want to delete this booking?</p></Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handlers.deleteBooking}>Yes</Button>
                        <Button variant="info" onClick={() => setShowDeleteBookingsModal(false)}>No</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showApplyModal} onHide={() => setShowApplyModal(false)}>
                    <Modal.Header><h3>Apply Booking</h3></Modal.Header>
                    <Modal.Body><p>Do you want to apply this booking?</p></Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => handlers.applyBooking(active)}>Yes</Button>
                        <Button variant="info" onClick={() => setShowApplyModal(false)}>No</Button>
                    </Modal.Footer>
                </Modal>showUploadOrderImages
                <OrderImagesModal role={role} show={showUploadOrderImages} setShow={setShowUploadOrderImages} data={formData} images={images} setImages={setImages} setCars={setBookings} />
            </div>
        </div>
    )
}

export default AdminBooking