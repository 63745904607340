import { Modal, Button } from "react-bootstrap"

const Notification = ({title, message, show, setShow}) => {
    return (
        <Modal show={show} onHide={() => setShow((prev) => ({...prev, show: false}))}>
            <Modal.Header><h4>{title}</h4></Modal.Header>
            <Modal.Body><p>{message}</p></Modal.Body>
            <Modal.Footer><Button variant="dark" onClick={() => setShow((prev) => ({...prev, show: false}))}>Close</Button></Modal.Footer>
        </Modal>
    )
}

export default Notification