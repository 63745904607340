import { useState } from "react";
const { Modal, Button } = require("react-bootstrap")

const AddChargesModal = ({ show, hide, cars, formData, setFormData, action, onSubmit }) => {
    
    return (
        show && <Modal show={show} onHide={() => hide(false)} size={"md"} centered>
            <Modal.Header><h4>{action === "add" ? "Add new expence" : "Edit expence"}</h4></Modal.Header>
            <Modal.Body>
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="form-group">
                            <label>Select Car</label>
                            <select 
                                className="form-control" 
                                onChange={(e) => {
                                    const selected = cars.filter((c) => c.id == e.target.value);
                                    console.log(selected)
                                    setFormData((prev) => {
                                        return {
                                            ...prev, carId: e.target.value, mark: selected[0].mark, model: selected[0].model, year: selected[0].year
                                        }
                                    })
                                }}
                                value={formData.carId}
                            >
                                <option>Select Car</option>
                                {
                                    cars.map((c) => {
                                        return <option key={c.id * 150} value={c.id}>{c.id} {c.mark} {c.model}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Date</label>
                            <input 
                                type="date" 
                                className="form-control"
                                onChange={(e) => setFormData((prev) => {
                                    const date = new Date(e.target.value);
                                    return {...prev, date: `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`}
                                })}
                                value={formData.date}
                            />
                        </div>
                        <div className="form-group">
                            <label>Expense type</label>
                            <input 
                                type="text" 
                                className="form-control"
                                onChange={(e) => setFormData((prev) => {
                                    console.log(formData)
                                    return {...prev, expenseType: e.target.value}
                                })}
                                value={formData.expenseType}
                            />
                        </div>
                        <div className="form-group">
                            <label>Description</label>
                            <textarea  
                                className="form-control"
                                onChange={(e) => setFormData((prev) => {
                                    return {...prev, description: e.target.value}
                                })}
                                value={formData.description}
                            />
                        </div>
                        <div className="form-group">
                            <label>Price</label>
                            <input 
                                type="text" 
                                className="form-control"
                                onChange={(e) => setFormData((prev) => {
                                    return {...prev, price: e.target.value}
                                })}
                                value={formData.price}
                            />
                        </div>
                        <Button variant="dark" type="submit">{action === "add" ? "Add new" : "Save"}</Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default AddChargesModal