import axios from "axios";
import { useEffect, useState } from "react";
import DataTable from "../../../Common/DataTable/DataTable";
import Notification from "../../../Hooks/Notification";

const AdminPayments = () => {
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [payments, setPayments] = useState([]);
    const [notification, setNotification] = useState({ show: false, title: "", message: "" });
    const [totalIncome, setTotalIncome] = useState("");
    const [income, setIncome] = useState("");
    const d = new Date();
    const [subagentIncome, setSubagentIncome] = useState("");
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    const [date, setDate] = useState({from: "", to: `${year}-${month}-${day}`});
    const [owner, setOwner] = useState();

    const headers = ["Name", "Surname", "Email", "Mark", "Model", "Date", "Price", "Income"];
    const showParams = [
        { type: "string", name: "name" }, { type: "string", name: "surname" }, { type: "string", name: "email" }, { type: "string", name: "mark" },
        { type: "string", name: "model" }, { type: "date", name: "date" }, { type: "string", name: "price" }, { type: "string", name: "income" }
    ];

    const selects = [
        {
            id: 500,
            action: () => {

            },
            options: [
                {
                    id: 501,
                    label: "Subagent",
                    value: "subagent"
                },
                {
                    id: 501,
                    label: "Our order",
                    value: "our order"
                }
            ]
        }
    ]

    const handlers = {
        search(keyword) {
            setKeyword(keyword);
        }
    }

    useEffect(() => {
        axios(`/api/payments?keyword=${keyword}&limit=${limit}&page=${page}&startDate=${date.from}&endDate=${date.to}`)
            .then((res) => {
                const data = res.data.data;
                setPayments([...data]);
                setTotal(res.data.total);
                setTotalIncome(res.data.sumAmount);
                setIncome(res.data.income);
                setSubagentIncome(parseInt(res.data.sumAmount) - parseInt(res.data.income));
            })
            .catch((err) => {
                const data = err.response.data;
                setNotification({ show: true, title: data.description, message: data.message })
            })
    }, [keyword, page, limit, date]);

    return (
        <div className="container">
            <div className="row">
                <div>
                    <DataTable
                        withoutSearchButton={false}
                        headers={headers}
                        searchHandler={handlers.search}
                        title={"Payments"}
                        limit={limit}
                        setLimit={setLimit}
                        limitControl={true}
                        data={payments}
                        showParams={showParams}
                        pageChanged={page}
                        setPageChanged={setPage}
                        total={total}
                        dateRange={true}
                        rowActions={false}
                        setDate={setDate}
                        date={date}
                        withSelectFilter={false}
                        selects={selects}
                        selectValue={owner}
                    />
                    <div>
                        <p>Total Amount: <strong>{totalIncome} EUR</strong></p>
                        <p>Total Income: <strong>{income} EUR</strong></p>
                        <p>Subagents Income: <strong>{subagentIncome} EUR</strong></p>
                    </div>
                </div>
                <Notification title={notification.title} message={notification.message} show={notification.show} setShow={setNotification} />
            </div>
        </div>
    )
}

export default AdminPayments